import React, { Component, useEffect } from 'react';
import Cookies from 'universal-cookie';
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'
import { ConfigProvider, notification } from 'antd';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
// import GoogleTagManager2 from './components/Widgets/GoogleTagManager';

//hooks
import { User, SetUser } from './Hooks/Logged';
import { Carrito, SetCarrito } from './Hooks/Carrito';
import { CarritoCompra, SetCarritoCompra } from './Hooks/CarritoCompra'; //carrito para ecommerce

//Componentes
import Routes from './Routes';

//syles
import './Styles/Theme/antd-manantial-theme.css';
import './App.css';
import './Styles/Global/global.css';

import es_ES from 'antd/lib/locale/es_ES';

const axios = require('axios')

axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE
axios.defaults.headers.post["Content-Type"] = "application/json";

const GoogleTagManagerBody = ({ gtmId }) => {
	useEffect(() => {
		const noscriptTag = document.createElement('noscript');
		noscriptTag.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

		// Prepend to body
		const body = document.body;
		if (body.firstChild) {
			body.insertBefore(noscriptTag, body.firstChild);
		} else {
			body.appendChild(noscriptTag);
		}

		// Cleanup on unmount
		return () => {
			body.removeChild(noscriptTag);
		};
	}, [gtmId]);

	// No se renderiza nada por React, el manejo es directo con el DOM
	return null;
};

// export default GoogleTagManagerBody;

const GoogleTagManager = ({ gtmId }) => {
	useEffect(() => {
		const scriptId = 'google-tag-manager';

		// Verifica si el script ya existe
		if (document.getElementById(scriptId)) {
			return;
		}

		// Crea un nuevo script
		const scriptTag = document.createElement('script');
		scriptTag.id = scriptId;
		scriptTag.async = true;
		scriptTag.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
		scriptTag.onload = () => {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'gtm.start': new Date().getTime(),
				event: 'gtm.js'
			});
		};

		// Inserta el script en el elemento <head>
		const head = document.head || document.getElementsByTagName('head')[0];
		head.insertBefore(scriptTag, head.firstChild);
	}, [gtmId]);

	return null;
};

/**
 *
 *
 * @class App
 * @extends {Component}
 */
class App extends Component {

	/**
	 *Creates an instance of App.
	 * @param {*} props
	 * @memberof App
	 */
	constructor(props) {
		super(props);

		this.state = {
			user: 0,
			cart: [],
			carrito_ecommerce: null,
		}
	}


	/**
	 *
	 *
	 * @memberof App
	 * 
	 */
	componentDidMount() {
		// TagManager.initialize(tagManagerArgs)
		ReactGA.initialize("G-W7MD9EBFEX");

		axios.interceptors.response.use(function (config) {
			return config;
		}, function (error) {
			console.log("error?.response?.status", error?.response?.status);
			if (403 === error?.response?.status)

				notification.error({
					message: error?.response?.data.message,
					description: (Array.isArray(error?.response?.data?.permisos)) ? <div>
						Debe disponer de alguno de los siguientes permisos para acceder.<br></br>
						<strong>{error?.response?.data?.permisos.map(e => e.split("_").map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(" ")).join(", ").replace(/, ([^,]*)$/, ' o $1')}</strong>
					</div> : "No dispone de los permisos suficientes.",
					placement: "bottomLeft"
				});

			return Promise.reject(error);
		});

		axios.get('/user/logged', {
			headers: { Authorization: sessionStorage.getItem('token') }
		})
			.then(({ data }) => {
				if(data?.data?.cliente_id){
					this.getCarrito();
				}
				this.setUser(data.data)
			})
			.catch((error) => {
				sessionStorage.clear()
				let cookie = new Cookies();
				cookie.remove("token")
			})

	}

	setUser = (user) => {
		this.setState({ user })
	};

	setCart = (cart = []) => {
		let cookie = new Cookies();
		this.setState({ cart }, () => {
			cookie.set("carrito", JSON.stringify(cart))
		})
	};

	setCarrito = (carrito_ecommerce) =>{
		this.setState({carrito_ecommerce})
	}

	getCarrito = () => {
        axios.get("/customer/carrito",{
			headers: { Authorization: sessionStorage.getItem('token') }
		})
        .then(async ({ data }) => {    
            this.setCarrito(data)
        })
        .catch(res => {
        	console.log("res", res.response);
            console.error(res?.response?.data?.message ?? 'No se pudo cargar el carrito de compra')
        })
    }

	render() {
		const { setUser, setCart, setCarrito } = this;
		const { user, cart, carrito_ecommerce } = this.state;

		return (
			<ConfigProvider locale={es_ES}>
				<GoogleTagManager gtmId={'GTM-PSRJ9GCF'} />
				<GoogleTagManagerBody gtmId={'GTM-PSRJ9GCF'} />

				<BrowserRouter>

					<User.Provider value={user}>
						<SetUser.Provider value={setUser}>

							<Carrito.Provider value={cart}>
								<SetCarrito.Provider value={setCart}>

									<CarritoCompra.Provider value={carrito_ecommerce}>
										<SetCarritoCompra.Provider value={setCarrito}>

											<Routes />
										
										</SetCarritoCompra.Provider>
									</CarritoCompra.Provider>

								</SetCarrito.Provider>
							</Carrito.Provider>

						</SetUser.Provider>
					</User.Provider>

				</BrowserRouter>
			</ConfigProvider>
		);
	}

}

export default (App);