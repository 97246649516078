import React, { Component } from 'react';
import { Button, Popconfirm, Space, Row, List, Col, Spin, PageHeader, Layout, message, Typography, Avatar, Collapse, Form, Tooltip, Switch } from 'antd'
import axios from 'axios';
import { FaFileDownload } from 'react-icons/fa';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

//Styles
import '../../../Styles/Modules/Productos/productos.css';

//Componentes
import { IconDelete, IconEdit } from '../../Widgets/Iconos';
import AvatarProductos from '../../Widgets/AvatarProductos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

//Modales
import ModalMultiple from "./ModalMultiple";
import ModalProductos from "./ModalProductos";
import ModalProductosEcommerce from "./ModalProductosEcommerce";

const { Content } = Layout;
const { Panel } = Collapse;
const { Text } = Typography

/**
 * @export
 * @class Productos
 * @extends {Component}
 * @description Vista de Productos
 */
export default class Productos extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			modalVisible: false,

			data: [],
			page: 1,
			limit: 10,
			total: 0,
			pages: 0,

			filtroSearch: '',
			searching: true,

			productos: {
			}
		}
	}

	componentDidMount() {
		//Mostrar input superior de busqueda 
		//Para acceder al valor del input = this.props.search
		this.props.updateFilterSearch(true);

		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getProductos();
	}


	/**
	 * @method componentDidUpdate
	 * @description Actualiza la vista si se buscan datos
	 */
	componentDidUpdate(prevProps) {

		const filtro = this.state.filtroSearch;
		const propFiltro = this.props.search;

		if (filtro !== propFiltro && this.state.searching == true) {
			this.getProductos();
			this.state.searching = false;
		}
	}



	/**
	 *
	 *
	 * @memberof Productos
	 * @description Obtiene la lista de productos agrupados por sku
	 */
	getProductos = ({ page, limit } = this.state) => {

		this.setState({ loading: true })
		let search = this.props.search;
		axios.get('/productos', {
			params: {
				page: page,
				limit: limit,
				groupBySKU: true,
				search: this.props.search
			}
		}).then(({ data }) => {
			this.setState(state => {
				state.limit = limit
				state.data = data.data.data
				state.total = data.data.total
				state.page = data.data.page
				state.searching = true
				state.filtroSearch = search
				return state;
			}, () => console.log(this.state))
		})
			.catch(error => {
				console.log(error)
				message.error('Error al traer la información')
			}).finally(() => this.setState({ loading: false }))
	}


	showModal = (producto_id = undefined) => this.setState({ modalProductos: true, producto_id })

	generarReporte = (values) => {

		if (!values.fecha) message.warning("Seleccione una fecha para generar el reporte")


		let fecha = values.fecha

		console.log(fecha)

		let fecha_inicio = fecha.startOf('month').toDate()
		let fecha_fin = fecha.endOf('month').toDate()

		this.setState({ reporteLoading: true })

		axios({
			url: '/productos/reporte',
			method: 'GET',
			responseType: 'blob', // important
			params: {
				fecha_inicio,
				fecha_fin
			}
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `Reporte de facturas ${fecha.format('MMMM YYYY')}.xlsx`);
				document.body.appendChild(link);
				link.click();
			})
			.finally(() => this.setState({ reporteLoading: false }))
	}

	toggleVisibleProducto = (visible, id) => {
		axios.put('/producto/update', { visible, id })
			.then(() => {
				message.success(`¡El producto ahora ${visible ? 'es visible' : 'está oculto'}!`)
			})
			.catch(() => {
				message.error(`¡Error al ${visible ? 'hacer visible' : 'ocultar'} el producto!`)
			})
	}

	render() {

		const { showModal } = this

		const contentMenuPlus = (
			<div className="flex-column">
				<Button type="primary" className="width-100 mb-05" onClick={() => showModal()}>
					Añadir Producto
				</Button>
				<Button type="primary" className="width-100 mb-05" onClick={() => this.setState({ modalVisibleCSV: true })}>
					Añadir Múltiples
				</Button>
				<Button type="primary" className="width-100" onClick={() => this.setState({ modalVisibleProductos: true })}>
					Añadir Productos ecommerce
				</Button>
			</div>
		);

		return (
			<Spin spinning={false}>
				<PageHeader
					className="site-page-header custom-page-header"
					title="Productos"
					extra={<Space>
						<Text className="text-white"> {this.state.total} Productos</Text>
						{/* <Form layout='inline' onFinish={this.generarReporte}>
							<Row>
								<Col span={6}>
									<Form.Item>
										<Tooltip placement="bottomRight" content="Generar reporte de facturas">
											<Button loading={this.state.reporteLoading} htmlType="submit" type="primary">
												<FaFileDownload />
											</Button>
										</Tooltip>
									</Form.Item>
								</Col>
							</Row>


						</Form> */}
					</Space>}
				/>
				<Content className="admin-content content-bg pd-1">
					<List
						locale={{ emptyText: "Sin Productos" }}
						dataSource={this.state.data}
						pagination={{
							current: this.state.page,
							pageSize: this.state.limit,
							total: this.state.total,
							hideOnSinglePage: true,
							position: 'bottom',
							className: "flex-left",
							pageSizeOptions: [10, 20, 50, 100, 500, 2500],
							onChange: (page, limit) => this.getProductos({ page, limit })
						}}
						loading={this.state.loading}
						className="scroll"

						renderItem={item => (
							<Collapse
								bordered={false}
								defaultActiveKey={['1']}
								expandIcon={({ isActive }) => isActive ? <MinusOutlined style={{ marginTop: 13, color: '#ffffff', fontSize: 16 }} /> : <PlusOutlined style={{ marginTop: 13, color: '#ffffff', fontSize: 16 }} />}
								className="collapse-productos"
							>
								<Panel
									header={
										<Row className="width-100 header-collapse">
											<Col xs={18} className="flex-left">
												<Text className="collapse-text-big">{item?._id}</Text>
											</Col>
											<Col span={5} className="flex-column">
												<Text>Productos</Text>
												<Text>{item?.count}</Text>
											</Col>
										</Row>
									}
								>
									<Row className="header-list width-100" >
										<Col xs={2} className="center">
											<Text strong>Imagenes</Text>
										</Col>

										<Col xs={3} className="center">
											<Text strong>Fabricante</Text>
										</Col>
										<Col xs={3} className="center">
											<Text strong>Marca</Text>
										</Col>
										<Col xs={3} className="center">
											<Text strong>SKU</Text>
										</Col>
										<Col xs={8} className="center">
											<Text strong>Descripción</Text>
										</Col>
										<Col xs={2} className="center">
											<Text strong>Visible</Text>
										</Col>
										<Col xs={3} className="center">
											<Text strong>Acciones</Text>
										</Col>
									</Row>
									{item.productos?.map(producto => {
										return <Row className="width-100 pt-1 pb-1">
											<Col xs={2} className="center">

												<AvatarProductos imagenes={producto.imagenes} />

											</Col>
											<Col xs={3} className="center">
												<Text>{producto.np_fabricante}</Text>
											</Col>
											<Col xs={3} className="center">
												<Text>{producto.marca}</Text>
											</Col>
											<Col xs={3} className="center">
												<Text>{producto.sku}</Text>
											</Col>
											<Col xs={8} className="center">
												<Text>{producto.descripcion}</Text>
											</Col>
											<Col xs={2} className="center">
												<Switch
													defaultChecked={producto.visible}
													onChange={(checked) => this.toggleVisibleProducto(checked, producto._id)}
												/>
											</Col>
											<Col xs={3} className="center">
												<Space>
													<Button type="primary" icon={<IconEdit />} onClick={() => showModal(producto._id)} />
													<Popconfirm
														placement="topRight"
														title="¿Deseas eliminar este Producto?"
														onConfirm={() => axios.delete('/producto/delete', { params: { id: producto._id } })
															.then((response) => {
																message.success("Eliminado Correctamente")
																this.getProductos()
															})
															.catch((error) => {
																message.error("No es posible eliminar")
																this.getProductos();
															})
														}
														okText="Si"
														cancelText="No"
													>
														<Button type="primary" danger icon={<IconDelete />} title="Eliminar" />
													</Popconfirm>
												</Space>
											</Col>
										</Row>
									})}
								</Panel>
							</Collapse>
						)}
					/>

					<FloatingButton title="Nuevo registro" content={contentMenuPlus} />
				</Content>
				<ModalProductos
					id={this.state.producto_id}
					visible={this.state.modalProductos}
					onCancel={() => {
						this.setState({ modalProductos: false })
						this.getProductos()
					}}
				/>

				<ModalMultiple
					visible={this.state.modalVisibleCSV}
					onClose={() => {
						this.setState({ modalVisibleCSV: false })
						this.getProductos()
					}}
				/>

				<ModalProductosEcommerce
					visible={this.state.modalVisibleProductos}
					onClose={() => {
						this.setState({ modalVisibleProductos: false })
						this.getProductos()
					}}
				/>
			</Spin>
		)
	}
}