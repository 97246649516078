import React, { useState } from 'react';
import { Button, Space, Typography } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const { Text } = Typography;

const NumericInput = ({ min = 1, max = 100, initialValue = 1 ,onChange = () => {} }) => {
  const [value, setValue] = useState(initialValue);

  const increase = () => {
    if (value < max) {
      const newValue = value + 1;
      setValue(newValue);
      onChange(newValue);
    }
  };

  const decrease = () => {
    if (value > min) {
      const newValue = value - 1;
      setValue(newValue);
      onChange(newValue);
    }
  };

  return (
    <Space>
      <Button 
        size="small" 
        onClick={decrease} 
        icon={<MinusOutlined style={{color: "#e76d5b"}} />} 
        style={{ borderColor: "#e76d5b", borderWidth: "2px" }}
      />
      <Text style={{fontSize: "16px", padding: "0px 1rem" }}>{value}</Text>
      <Button 
        size="small" 
        onClick={increase} 
        icon={<PlusOutlined style={{color: "#e76d5b"}}/>} 
        style={{ borderColor: "#e76d5b", borderWidth: "2px" }}
      />
    </Space>
  );
};

export default NumericInput;
