import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, Result, Layout } from 'antd';

const { Text } = Typography;
const { Content } = Layout;

export default function (props) {

    const { venta } = props

    let history = useHistory()

    return <Content style={{minHeight: "600px"}} className="center">
    	<Result
		    status="success"
		    title="Compra realizada con exito"
		    subTitle={ <Text>
                Número de folio: <strong>{venta?.folio ?? "-"}</strong>. Se le enviará un correo de confirmación de su orden. 
                Gracias por su compra. Puede hacer seguimiento de su pedido en su cuenta.
            </Text>}
		    extra={[
		      	<Button type="primary" key="store" onClick={() => history.push("/productos")}>
                	Ir a la Tienda
            	</Button>,
            	<Button key="orders" onClick={() => history.push("/customer/misPedidos")}>
                	Ver mis pedidos
            	</Button>,
		    ]}
		/>
	</Content>

}