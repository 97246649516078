import React from 'react'

/**
 * Este hook es para guardar el carrito de compra de los clientes
 * Para los productos que estan diseñados para venderse en el ecommerce
 * NO para los productos que son para cotizacion (para esos es el otro hook de Carrito)
 * */
let CarritoCompra = React.createContext([]);
let SetCarritoCompra = React.createContext(() => {});

export {
    CarritoCompra,
    SetCarritoCompra
}



