import React, { Component, createRef, useContext } from "react";
import { Button, Layout, Image, Typography, Row, Col, Divider, Card, Carousel, Select, Form, List, Collapse, Space, message, Statistic } from 'antd';
import axios from "axios";
import { Link, useLocation, useHistory } from 'react-router-dom'
import { ShoppingCartOutlined } from "@ant-design/icons";

//componentes
import Footer  from "../../Footer/Footer";
import { User } from "../../../Hooks/Logged";
import { ProductoCard } from '../../Widgets/Cards';
import NumericInput from '../../Widgets/Inputs/NumericInput';
import { CarritoCompra, SetCarritoCompra } from "../../../Hooks/CarritoCompra";

//estilos
import '../../../Styles/Global/footer.scss'
import '../../../Styles/Global/productos.scss'
import 'iseeyoutech-widget-web/dist/style.css'

const { Content } = Layout
const { Title, Paragraph, Text } = Typography
/**
 *
 *
 * @export
 * @class ProductoDetalle
 * @extends {Component}
 * @description Pagina donde se muestra a detalle el producto
 */ 
class ProductoDetalle extends Component {

    constructor(props){
        super(props)
        this.state = {
           loading: false,
           carrito_compra: null
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if(this.props?.carrito_compra?._id || this.props.user){
        	this.getCarrito()
        }
    }

    componentDidUpdate(prevProps){
        if(this.props?.user?._id && this.props?.user?._id !== prevProps?.user?._id){
            this.getCarrito()
        }
       
    }

    /**
     * @memberof ProductoDetalle
     * @description Obtenemos el carrito de compra del usaurio logeado
     */
    getCarrito = () => {
        this.setState({ loading: true })
        axios.get("/customer/carrito")
        .then(async ({ data }) => {
        	console.log("data", data);
           	this.setState({carrito_compra: data})
            if(this.props.setCarritoCompra){
                this.props.setCarritoCompra(data)
            }
        })
        .catch(res => {
            message.error(res?.response?.data?.message ?? 'No se pudo cargar el carrito de compra')
            
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof onChangeCantidad
     * @description Actualiza la cantidad de productos del carrito
     */
    onChangeCantidad = (cantidad, producto_id) => {
        this.setState({loading: true})
        axios.put('/customer/item/update',{
            cantidad,
            producto_id,
        }).then(({data})=>{
            console.log("data", data);
            this.setState({ carrito_compra: data })
            if(this.props.setCarritoCompra){
                this.props.setCarritoCompra(data)
            }          

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al actualizar el producto")

        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof deleteItem
     * @description Elimina un producto del carrito de compra
     */
    deleteItem = (producto_id) => {
        this.setState({loading: true})
        axios.post('/customer/item/delete',{
            producto_id,
        }).then(({data})=>{
            console.log("data", data);
            this.setState({ carrito_compra: data })
            if(this.props.setCarritoCompra){
                this.props.setCarritoCompra(data)
            }          

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al eliminar el producto")

        }).finally(()=>this.setState({loading: false}))
    }

     /**
     * @memberof getURLImage
     * @description retorna la url de la imagen, dependiendo de que objeto se manda
     */
    getURLImage = (image) => {
        console.log("image", image);

    	if(image == null){
    		return "/images/nofile.png"
    	}

        if(image.filename){
            console.log("image.filename", image.filename);
            return axios.defaults.baseURL + '/upload/' + image.filename
        }

    	return image.url
    }

    render() {

        const { producto } = this.state;

        return (
            <Layout className="productos-page">
                <Content className="productos-main carrito-compra">
                    <Row gutter={[30, 30]} style={{width: "100%"}} className="">
                        <Col span={12}>
                           <Title level={3}>Carrito de compra</Title>
                        </Col>
                        <Col span={12}>
                            <Card size="small">
                                <Row>
                                    <Col span={24} className="center">
                                        <Space className="carrito-total">
                                            <Text>Total: (Productos {this.state.carrito_compra?.items?.length ?? 0})</Text>
                                            <Statistic 
                                                value={this.state.carrito_compra?.total ?? 0} 
                                                precision={2} 
                                                prefix="$" 
                                                suffix="MXN"
                                            />
                                        </Space>
                                    </Col>
                                    <Col span={24} className="flex-right">
                                        <Button type="primary" onClick={()=>this.props.history.push("/carrito-de-compra-pago")}>Proceder a Pagar</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24}>
                           <List
                                loading={this.state.loading}
                                size="large"
                                itemLayout="horizontal"
                                dataSource={this.state.carrito_compra?.items}
                                renderItem={item => (
                                    <List.Item className="" key={item._id}>
                                        <Row className="width-100 ">
                                            <Col span={8} className="center ">
                                                <Image 
                                                    src={this.getURLImage(item?.producto?.imagenes ? item?.producto?.imagenes[0] : null)} 
                                                    height={200}
                                                    width={200}
                                                />
                                            </Col>
                                            <Col span={16}>
                                                <Row>
                                                    <Col span={24}>
                                                        <Text className="producto-title">{item.producto?.descripcion}</Text>
                                                    </Col>
                                                    <Col span={24} className="producto-price-col">
                                                        <Space>
                                                            <Text className="carrito-label">Precio:</Text> 
                                                            <Statistic 
                                                                value={item?.precio_unitario} 
                                                                precision={2} 
                                                                prefix="$" 
                                                                suffix="MXN"
                                                                className="producto-price"
                                                            />
                                                        </Space>
                                                    </Col>
                                                    <Col span={12} className="producto-cantidad-col ">
                                                        <NumericInput 
                                                            initialValue={item.cantidad}
                                                            onChange={(cantidad)=>this.onChangeCantidad(cantidad, item.producto_id)}
                                                        />
                                                        <Text 
                                                            className="label-action-gray"
                                                            onClick={()=>this.deleteItem(item.producto_id)}
                                                        >Eliminar</Text>
                                                    </Col>
                                                    <Col span={12} className="">
                                                        <Text>Subtotal: </Text> <Statistic value={item.subtotal} precision={2} prefix="$" suffix="MXN"/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )}

                            />
                        </Col>
                        <Col span={12}>
                            {/*Vacio*/}
                        </Col>
                        <Col span={12}>
                            { this.state.carrito_compra?.items?.length > 3 ? <Card size="small">
                                <Row>
                                    <Col span={24} className="center">
                                        <Space className="carrito-total">
                                            <Text>Total: (Productos {this.state.carrito_compra?.items?.length ?? 0})</Text>
                                            <Statistic 
                                                value={this.state.carrito_compra?.total ?? 0} 
                                                precision={2} 
                                                prefix="$" 
                                                suffix="MXN"
                                            />
                                        </Space>
                                    </Col>
                                    <Col span={24} className="flex-right">
                                        <Button type="primary">Proceder a Pagar</Button>
                                    </Col>
                                </Row>
                            </Card> : null}
                        </Col>
                    </Row>
                </Content>
                <Footer />
            </Layout>
        )
    }

}

export default (props) => {

    return <ProductoDetalle 
        {...props}
        user={useContext(User)}
        carrito_compra={useContext(CarritoCompra)}
        setCarritoCompra={useContext(SetCarritoCompra)}
        history={useHistory()}
    />
}