import React, { Component, createRef, useContext } from "react";
import { Button, Layout, Image, Typography, Row, Col, Divider, Card, Carousel, Select, Form, List, Collapse, Radio, message, notification, Space, Tag } from 'antd';
import axios from "axios";
import { Link, useLocation } from 'react-router-dom'

//Componentes
import Footer from "../Footer/Footer";
import { User } from "../../Hooks/Logged";
import { ProductoCard } from '../Widgets/Cards'
import DrawerFamilias from '../Widgets/DrawerFamilias'
import Carrito, { SetCarrito } from "../../Hooks/Carrito";
import { CarritoCompra, SetCarritoCompra } from "../../Hooks/CarritoCompra";

//estilos
import '../../Styles/Global/footer.scss'
import '../../Styles/Global/productos.scss'
import 'iseeyoutech-widget-web/dist/style.css'

const { Content } = Layout
const { Title, Paragraph, Text } = Typography
/**
 *
 *
 * @export
 * @class Productos
 * @extends {Component}
 * @description Pagina principal del sistema
 */ 
class Productos extends Component {

    constructor(props){
        super(props)
        this.state = {
            productos: {
                docs: [],
                page: 1,
                limit: 15,
                search: null,
                filters: {

                },
                sort: {
                    createdAt: -1
                },
                sortValue: undefined,
            },
            familias_seleccionadas: {},
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        let params = new URLSearchParams(this.props.location?.search)
        let search = params.get('search')
        this.getProductos({search})
    }

    componentDidUpdate(prevProps){
        if(prevProps.location?.search !== this.props.location?.search){
            let params = new URLSearchParams(this.props.location?.search)
            let search = params.get('search')
            this.getProductos({search})
        }
    }

    getProductos = ({
        page = this.state.productos.page,
        limit = this.state.productos.limit,
        sort = this.state.productos.sort,
        filters = this.state.productos.filters,
        search = this.state.productos.search
    } = this.state.productos) => {
        this.setState({loading: true})
        axios.get('/public/productos', {
            params: {
                page,
                limit,
                sort,
                filters,
                search,
                familias_ids: Object.keys(this.state.familias_seleccionadas),
            }
        }).then(({data}) => {
            this.setState({productos: {...data, search}})
        }).catch((err) => {
            message.error("Error al obtener los productos")
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof Productos
     * @description añade el producto al carrito de compra del ecommerce
     */
    addItem = (producto_id) => {

        if(!this.props.user){
            notification.error({
                message: "Usuario no logueado",
                description: "Es necesario iniciar sesión para añadir productos al carrito de compra.",
                placement: "topRight",
            });

            return;
        }

        this.setState({loading: true})
        axios.post('/customer/item/add',{
            producto_id,
        }).then(({data})=>{
            if(this.props.setCarritoCompra){
                this.props.setCarritoCompra(data.carrito)
            }
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al añadir el producto al carrito")

        }).finally(()=>this.setState({loading: false}))
    }


    renderFamilias = () => {

        return Object.entries(this.state.familias_seleccionadas || {}).map(([key, value]) => (
            <Tag key={key}>{value}</Tag>
        ));

    }

    render() {
        return (
            <Layout className="productos-page">
                <Content className="productos-main ">
                    <Row gutter={[30, 30]} className="">
                        <Col xs={24} lg={5} className="productos-header">
                            <Title level={1} className="productos-title">Catalogo</Title>
                        </Col>
                        <Col xs={24} lg={19} className="productos-sorts">
                            <Space>
                                <Button onClick={()=>this.setState({drawer_familias: true})}>Categorias</Button>
                                <Select 
                                    placeholder="Seleccionar orden"
                                    className="sorts-select"
                                    onSelect={({value, label}) => {
                                        const sort = {}
                                        sort[value[0]] = value[1]

                                        this.getProductos({sort})
                                        this.setState({sortValue: {value, label}})
                                    }}
                                    value={this.state.sortValue}
                                    labelInValue
                                >
                                    <Select.Option value={['createdAt', -1]}>
                                        Mas recientes
                                    </Select.Option>
                                    <Select.Option value={['createdAt', 1]}>
                                        Mas antiguos
                                    </Select.Option>
                                </Select>
                            </Space>
                        </Col>
                        <Col xs={24}>
                            <Space>
                                {this.renderFamilias()}
                            </Space>
                        </Col>
                        <Col xs={24}>
                            <List
                                grid={{
                                    column: 5,
                                    gutter: [16, 16],
                                    lg: 4,
                                    md: 3,
                                    xs: 2
                                }}
                                dataSource={this.state.productos.docs}
                                pagination={{
                                    pageSize: this.state.productos.limit,
                                    current: this.state.productos.page,
                                    total: this.state.productos.totalDocs,
                                    onChange: (page) => this.getProductos({page}),
                                    onShowSizeChange: (page, limit) => this.getProductos({page, limit})
                                }}
                                loading={this.state.loading}
                                renderItem={(item) => (
                                    <List.Item>
                                        <ProductoCard 
                                            producto={item}
                                            onAdd={() => {
                                                let is_added = this.props.carrito?.find(p => p._id === item._id)
                                                if(is_added){
                                                    message.info("El producto ya esta agregado")
                                                    return
                                                }

                                                const carrito = this.props.carrito
                                                carrito.push(item)
                                                this.props.setCarrito(carrito)

                                                message.success("Producto agregado al carrito")
                                            }}

                                            onAddCarritoCompra={()=>this.addItem(item._id)}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Content>
                <Footer/>
                <DrawerFamilias
                    open={this.state.drawer_familias}
                    onClose={()=>this.setState({drawer_familias: false})}
                    onSelectFamilia={(familias)=>this.setState({familias_seleccionadas: familias},()=>this.getProductos())}
                />
            </Layout>
        )
    }

}

export default (props) => {

    return <Productos 
        {...props}  
        carrito={useContext(Carrito)} 
        setCarrito={useContext(SetCarrito)}
        user={useContext(User)}
        setCarritoCompra={useContext(SetCarritoCompra)}
    />
}