import React from 'react'
import { Route, Switch } from "react-router-dom";

// componentes
import Ventas from '../components/Admin/Ventas/Ventas';
import VentaDetalle from '../components/Admin/Ventas/VentaDetalle';

/**
 * @function RouterVentas
 */
function RouterVentas( props) {
    return (
        <Switch>
            <Route exact path="/admin/ventas-ecommerce" render={() => <Ventas {...props} />} />
            <Route exact path="/admin/ventas-ecommerce/:venta_id" render={(props_ruta) => <VentaDetalle {...props} {...props_ruta}/>} />
        </Switch>
    )
}

export default RouterVentas;
