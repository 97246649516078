import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Spin, Upload, List, message } from 'antd';
import { UploadOutlined, } from '@ant-design/icons';

import { IconTemplate } from '../../Widgets/Iconos';


const { Title } = Typography;
const axios = require('axios').default;


/**
 *
 *
 * @class ModalMultiplePoductos
 * @extends {Component}
 */
class ModalMultiplePoductos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            tipo: 1,
        }
    }

    ModalMultiplePoductos = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.proveedor_id !== undefined) {
            this.getProveedor()
        }
    }


    
    /**
     * @memberof ModalMultiplePoductos
     * @method onFinish
     * @description Sube el docuemnto para poder agregar varios productos
     */
    normFile = (e) => {
        const { file, fileList } = e;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.setState({ update: !this.state.update, loading: true })
                break;
            }
        }


        if(file.status === "error"){
            this.props.onClose()
            message.error(file?.response?.message ?? "Error al subir el archivo")
        }

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
            });

            this.props.onClose()
            Modal.info({
                title: 'Archivo leido correctamente',
                content: (
                  <div>
                    <p>Productos registrados correctamente: {e.file.response.exitos}</p>
                    <p>Productos NO registrados: {e.file.response.errores}</p>
                  </div>
                ),
                onOk() {},
            });
        }

        return e && e.fileList;

    };


    render() {

       
        return (
            <Form
                layout="vertical"
                ref={this.ModalMultiplePoductos}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>
                    <Row align="center" style={{ margin: "10px 0px" }}>
                        <img src="/Icons/URREA_logo.jpg" alt="Subir archivo Excel" style={{ maxWidth: 250 }}/>
                    </Row>
                    <Row align="center" style={{ margin: "20px 0px", padding: '10px 0px' }}>
                        <List
                            style={{  }}
                            align="center"
                            size="small"
                            header={<div>Toma en cuenta las siguientes consideraciones.</div>}
                            dataSource={[
                            	<div style={{ width: '100%' }}>- Sube el archivo oficial de <strong>Urrea</strong> (.xlsx) con los productos para cargarlos en el sistema.</div>,
                                <div style={{ width: '100%' }}>- Cambia el nombre de la columna de <strong>Precio Sub distribuidor</strong> a solamente <strong>costo</strong></div>,
                                <div style={{ width: '100%' }}>- El costo no debe de estar en ningún formato de moneda</div>]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />

                    </Row>
                    <Row align="center" style={{ margin: "10px 0px" }}>
                        <img src="/Icons/upload.svg" alt="Subir archivo Excel" style={{ maxWidth: 250 }}/>
                    </Row>

                    <Row className="ant-row-center">

                        <Col xs={24} lg={20} className="mt-3 mb-3" >
                            <Form.Item
                                label="Archivo (.xlsx)"
                                name="documentos"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                                <Upload
                                    accept={'csv'}
                                    headers={{ Authorization: axios.defaults.headers.common['Authorization'] }}
                                    action={axios.defaults.baseURL + "/voucher/add/excel/productos"}
                                    max={1}
                                    className="width-100"
                                >
                                    <Button className="width-100">
                                        <UploadOutlined /> Agregar archivos
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Vista(props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Guardar Productos </Title>
        </div>
        <ModalMultiplePoductos {...props} />
    </Modal>

}