import React, { Component, createRef, useContext } from "react";
import { Button, Layout, Image, Typography, Row, Col, Divider, Card, Carousel, Select, Form, List, Collapse, Space, message, Statistic, Radio, Modal } from 'antd';
import axios from "axios";
import { Link, useLocation } from 'react-router-dom'
import { ShoppingCartOutlined } from "@ant-design/icons";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, } from '@stripe/react-stripe-js';

//componentes
import Footer  from "../../Footer/Footer";
import VentaExitosa from "./VentaExitosa";
import { User } from "../../../Hooks/Logged";
import ModalDireccion from "./ModalDireccion";
import { ProductoCard } from '../../Widgets/Cards';
import NumericInput from '../../Widgets/Inputs/NumericInput';
import StripeEcommerce  from '../../Widgets/paypear/PaymentForm/Stripe-ecommerce';
import { CarritoCompra, SetCarritoCompra } from "../../../Hooks/CarritoCompra";

//estilos
import '../../../Styles/Global/footer.scss'
import '../../../Styles/Global/productos.scss'
import 'iseeyoutech-widget-web/dist/style.css'

const { Content } = Layout
const { Title, Paragraph, Text } = Typography

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, { locale: 'en' });

/**
 *
 *
 * @export
 * @class ProductoDetalle
 * @extends {Component}
 * @description Pagina donde se muestra a detalle el producto
 */ 
class ProductoDetalle extends Component {

    constructor(props){
        super(props)
        this.state = {
           loading: false,
           carrito_compra: null,
           loading_direcciones: false,
           direcciones: [],
           direccion_id: null,
           direccion_selected_id: null,
           venta: null,
        }
    }

    stripeRef = createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if(this.props?.carrito_compra?._id || this.props.user){
        	this.getCarrito()
            this.getDirecciones()
        }
    }

    componentDidUpdate(prevProps){
        if(this.props?.user?._id && this.props?.user?._id !== prevProps?.user?._id){
            this.getCarrito()
            this.getDirecciones()
        }   
    }

     /**
     * @memberof ProductoDetalle
     * @description Obtenemos las direcciones del usuario
     */
    getDirecciones = () => {
        this.setState({ loading_direcciones: true })
        axios.get("/direcciones")
        .then(async ({ data }) => {
            this.setState({
                direcciones: data.data
            })
        })
        .catch(res => {
            console.log("resaaa", res);
            message.error(res?.response?.data?.message ?? 'Error al cargar las direcciones')
            
        }).finally(() => this.setState({ loading_direcciones: false }))
    }

    /**
     * @memberof ProductoDetalle
     * @description Obtenemos el carrito de compra del usaurio logeado
     */
    getCarrito = () => {
        this.setState({ loading: true })
        axios.get("/customer/carrito")
        .then(async ({ data }) => {
        	console.log("data", data);
           	this.setState({carrito_compra: data})
            if(this.props.setCarritoCompra){
                this.props.setCarritoCompra(data)
            }
        })
        .catch(res => {
            message.error(res?.response?.data?.message ?? 'No se pudo cargar el carrito de compra')
            
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalDirecciones
     * @method deleteDireccion
     * @description Añade una nueva direccion
     */
    deleteDireccion = (direccion_id) => {

        Modal.confirm({
            title: "¿Desea eliminar la dirección?",
            content: "Esta acción eliminará permanentemente la dirección seleccionada.",
            onOk: () => {
                this.setState({ loading: true });
                axios.delete('/direccion',{
                    params:{
                        direccion_id: direccion_id,
                    }
                }).then(({data}) => {
                    message.success("Dirección eliminada con exito")
                    this.getDirecciones()
                }).catch(error => {
                    console.log("error", error);
                    message.error("Error al eliminar la dirección")
                }).finally(()=>this.setState({loading: false}))
            }
        })

    }


     /**
     * @memberof ModalDirecciones
     * @method handleSubmit
     * @description ejecuta el handleSubmit del componente de Stripe
     */
    handleSubmit = () => {

        if(!this.state.direccion_selected_id){
            message.error("Dirección no seleccionada")
        }

        if (this.stripeRef.current) {
            this.stripeRef.current.submitPayment();
        }else{
            message.error("Componente Stripe no encontrado")
        }
    }

     /**
     * @memberof ModalDirecciones
     * @method procederPago
     * @description Añade una nueva direccion
     */
    procederPago = (stripe_id) => {

        this.setState({ loading: true })
        axios.post('/customer/carrito/pay',{
            direccion_id: this.state.direccion_selected_id,
            id: stripe_id
        }).then(({data}) => {
            console.log("data", data);
            this.setState({
                venta: data.venta
            })
            this.props.setCarritoCompra(null)
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al porcesar el pago.")
        }).finally(()=>this.setState({loading: false}))

    }

     /**
     * @memberof getURLImage
     * @description retorna la url de la imagen, dependiendo de que objeto se manda
     */
    getURLImage = (image) => {

    	if(image == null){
    		return "/images/nofile.png"
    	}

        if(image.filename){
            return axios.defaults.baseURL + '/upload/' + image.filename
        }

    	return image.url
    }

    render() {

        const { producto } = this.state;

        if(this.state.venta){
            return <>
                <VentaExitosa venta={{...this.state.venta}}  />
                <Footer />
            </>
        }

        return (
            <Layout className="productos-page">
                <Content className="productos-main carrito-compra">
                    <Row gutter={[30, 30]} style={{width: "100%"}} className="">
                        <Col span={16} className="">
                           	<Title level={3}>Realizar Pago</Title>
                           	<Divider orientation="left">Dirección de envio</Divider>
                           	<List
                           		dataSource={this.state.direcciones}
                                footer={<div>
                                    <Text 
                                        className="label-action-gray"
                                        onClick={()=>this.setState({modal_direccion: true})}
                                    >Agregar Dirección</Text>
                                </div>}
                           		renderItem={(item, index) => (
                           			<Card size="small" style={{marginBottom: "4px"}} key={index}>
                           				<Row>
                           					<Col span={2} className="center">
                           						<Radio 
                                                    checked={this.state.direccion_selected_id === item._id} 
                                                    className="m-0"
                                                    onClick={(check)=>{
                                                        if(check.target.checked)
                                                            this.setState({direccion_selected_id: item._id})
                                                    }}
                                                />
                           					</Col>
                           					<Col span={22}>
                           						<Title level={5} className="m-0">{item?.nombre ?? "-"}</Title>
                           						<div>{item?.direccion ?? "-"}, { item?.ciudad ? `${item.ciudad}, ` : "" } { item?.estado ? `${item.estado}, ` : "" } { item?.codigo_postal ? `${item.codigo_postal}, ` : "" } México</div>
                           						<div>Tel. {item.telefono ?? "-"}</div>
                                                <Space>
                                                    <Text 
                                                        className="label-action-gray"
                                                        onClick={()=>this.setState({modal_direccion: true, direccion_id: item._id})}
                                                    >Modificar</Text>
                                                    <Text 
                                                        className="label-action-gray"
                                                        onClick={()=>this.deleteDireccion(item._id)}
                                                    >Eliminar</Text>

                                                </Space>
                           					</Col>
                           				</Row>
                           			</Card>
                           		)}
                           	/>
                           	<Divider orientation="left">Método de pago</Divider>
                            <Row>
                                <Col span={24}>
                                    <Elements stripe={stripePromise}>
                                        <StripeEcommerce
                                            ref={this.stripeRef}
                                            procederPago={this.procederPago}
                                        />
                                    </Elements>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8} className="">
                            <Card size="small">
                                <Row>
                                    <Col span={24} className="center">
                                        <Space direction="vertical" className="carrito-total">
                                            <Text>Total: (Productos {this.state.carrito_compra?.items?.length ?? 0})</Text>
                                            <Statistic 
                                                value={this.state.carrito_compra?.total ?? 0} 
                                                precision={2} 
                                                prefix="$" 
                                                suffix="MXN"
                                            />
                                        </Space>
                                    </Col>
                                    <Col span={24} className="flex-right">
                                        <Button 
                                            type="primary"
                                            loading={this.state.loading}
                                            onClick={()=>this.handleSubmit()}
                                        >Proceder a Pagar</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Content>
                <Footer />
                
                <ModalDireccion
                    visible={this.state.modal_direccion}
                    onClose={(flag)=>{
                        this.setState({modal_direccion: false, direccion_id: undefined})
                        if(flag){
                            this.getDirecciones()
                        }
                    }}
                    direccion_id={this.state.direccion_id}
                />
            </Layout>
        )
    }

}

export default (props) => {

    return <ProductoDetalle 
        {...props}
        user={useContext(User)}
        carrito_compra={useContext(CarritoCompra)}
        setCarritoCompra={useContext(SetCarritoCompra)}
    />
}