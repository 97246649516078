import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { default as axios } from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Row, Col, message, Spin, Button } from 'antd';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './Stripe.css';

const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#c4f0ff',
            color: 'black',
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': { color: '#e5edf5' },
            '::placeholder': { color: '#bfc0d2' },
        },
        invalid: {
            iconColor: '#bfc0d2',
            color: 'black',
        },
    },
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, { locale: 'en' });

const StripeEcommerce = forwardRef((props, ref) => {

    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    let { procederPago = () => {} } = props;

    const handleSubmit = async () => {
        setLoading(true);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (!error) {
            const { id } = paymentMethod;
            console.log("paymentMethod", paymentMethod);

            try {
                console.log("Exito");
                procederPago(id);
            } catch (e) {
                console.error(e);
                message.error('Error en el proceso de pago. Intenta nuevamente.');
            }
        } else {
            message.error('Error producido por Stripe');
            console.error(error);
        }
        setLoading(false);
    };

    // Expone handleSubmit al componente padre
    useImperativeHandle(ref, () => ({
        submitPayment: handleSubmit,
    }));

    return (
        <Spin spinning={loading}>
            <form onSubmit={handleSubmit}>
                <div style={{ marginBottom: '2em' }}>Ingresa tu información de pago.</div>
                <fieldset className="FormGroup">
                    <div className="FormRow">
                        <CardElement options={CARD_OPTIONS} id="card-element" aria-label="Información de tarjeta" />
                    </div>
                </fieldset>
            </form>
        </Spin>
    );
});

export default StripeEcommerce;