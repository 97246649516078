import React from 'react'
import { Route, Switch } from "react-router-dom";


// componentes
import Compras from '../../components/Customer/Compras/Compras'

/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterMisPedidos(props) {

    return (
        <Switch>
            <Route exact path="/customer/compras" render={() => <Compras {...props} />} />
        </Switch>
    )
}

export default RouterMisPedidos;
