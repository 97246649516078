import React, { useEffect, useState, useContext } from 'react'
import ReactEcharts from "echarts-for-react";
import { Link, Redirect, useHistory } from "react-router-dom";

import { 
    ShoppingCartOutlined, 
    FieldTimeOutlined, 
    EyeOutlined, 
    FileExcelOutlined, 
    ExclamationCircleOutlined, 
    FileDoneOutlined, 
    WarningOutlined, 
    CheckCircleFilled, 
    CloseCircleFilled, 
    WarningFilled, 
    ArrowUpOutlined, 
    FlagFilled, 
    ArrowDownOutlined, 
    AntDesignOutlined, 
    CloseOutlined, 
    DeleteFilled, 
    EditFilled, 
    FormOutlined 
} from "@ant-design/icons";
import { Dropdown, Result, Spin, Popover, DatePicker, Statistic, Card, Tag, List, Checkbox, Typography, message, Row, Col, Image, Progress, Avatar, Button, Space, Popconfirm, Modal, Empty, Tooltip } from 'antd';

import CustomAvatar from './Avatar/Avatar';
import { LightenDarkenColor } from 'lighten-darken-color';
import {
    IconSuccess, IconError, IconWarning,
    IconVisa, IconMaster, IconCheckMedal, IconProfit, IconLoss, IconToolOperation, IconMenuDots, IconFinanzas, IconEdit, IconDelete, IconDetails, IconMail, IconAceptarOrden, IconPDF, IconPdtCot, IconPdtAcp, IconPrdAcp, IconPrdPrc, IconBtnOpcExclamacion, IconBtnOpcCancelar, IconBtnOpcAceptar,
} from './Iconos';
import { MdAttachMoney } from "react-icons/md"
import '../../Styles/Global/cards.scss';
import ModalEditarVigencia from '../../components/Admin/ProjectManager/Matriz/ModalEditarVigencia';


import { User, SetUser } from '../../Hooks/Logged'

import 'moment/locale/es'

import moment from 'moment-timezone'
import { LoginForm } from '@ant-design/pro-form';

moment.locale('es');
// moment.tz.locale('es')

moment.tz.setDefault("America/Mexico_City");

const { Text, Title, Paragraph } = Typography;
const axios = require('axios');

/**
 * @const CardCustomerProjectInfo
 * @description Card de mis pedidos para customers
 * @param users Array de Usuarios
 * @param project Object con la informacion del projecto
 * @param color Color del ProgressBar 
 */

const CardCustomerProjectInfo = ({
    orden = {},
}) => {

    return <Card title={orden.folio} className="card-project card-shadow card-medium-height">
        <Card.Meta
            description={<p><FlagFilled /> <span className='mb-1'>{moment(orden.createdAt).fromNow(true)}</span> <small>({moment(orden.createdAt).format("LLL")})</small></p>}
        />
        <Row className="mb-1 mt-1">
            <Col span={24} className="card-project-info">{orden.cliente_id?.nombre}</Col>
            <Col span={24} className="card-project-info">{orden.cliente_id?.razon_social}</Col>
            <Col span={24} className="card-project-info">{orden.cliente_id?.telefono} /<span>{orden.cliente_id?.email}</span></Col>
        </Row>

        <Row className='mb-1'>
            <Col span={24}>
                <span className='spn-card-estatus'>Fecha de Entrega</span>
            </Col>
        </Row>

        <Row>
            <Col span={24} className="card-project-info">
                <Text>{orden.fecha_entrega ? moment(orden.fecha_entrega).format('DD/MM/YYYY') : 'Sin Asignar'}</Text>
            </Col>
        </Row>
    </Card>
}

const CardCustomerProgresoOrden = ({ color = "#46BD84", quote_day, percent = 0, started = null } = {}) => {
    return <Card
        className="card-task-progress card-shadow card-medium-height"
        title="Progreso Orden">
        <Row className='mb-1'>
            <Card.Meta
                description={<p> Inicio  {moment(quote_day).format("LLL")} </p>}
            />
        </Row>

        <Row align="middle" justify="center" >
            <Col className="block-center">
                <Progress type="circle" percent={percent?.toMoney()} width={150} strokeColor={color} />
            </Col>
        </Row>
    </Card>
}


const CardCustomerEntrega = ({ tiempo = 0, precio = 0, detalles = 1, direccion = null }) => {
    return <Card
        className="card-address card-shadow card-medium-height"
    >
        <Row className='mb-2'>
            <Col span={24}>
                <label className="tag-card-blue">Lugar de Entrega</label>
            </Col>
            <Col span={24}>
                <p className="mt-1">{direccion ? direccion : 'N/A'}</p>
            </Col>
        </Row>

        <Row>
            <Col span={24}>
                <label className="tag-card-blue">Prioridad</label>
            </Col>
            <Col span={24}>
                <p className="mt-1"><FieldTimeOutlined /> Tiempo de Entrega : {tiempo} </p>
                <p className="mt-1"><MdAttachMoney /> Precio : {precio} </p>
            </Col>

        </Row>
    </Card>
}


const CardCustomerRowCotizacion = (props) => {

    let { orden } = props

    const renderTag = (status) => {

        let tags = {
            1: <Tag color="blue">Creada</Tag>,
            2: <Tag color="purple">Enviada</Tag>,
            3: <Tag color="green">Aceptada</Tag>,
            4: <Tag color="red">Rechazada</Tag>,
            5: <Tag color="purple">Recotizando</Tag>,

        }
        return status ? tags[status] : <Tag>N/A</Tag>
    }


    let statusCotizacion = null
    if (orden.cotizacion_id?.status == 3) {
        statusCotizacion = <div>
            <CheckCircleFilled style={{ fontSize: 23, display: "block" }} />
            <Title level={5} style={{ display: "block" }}>Cotización Aceptada</Title>
        </div>

    } else if (orden.cotizacion_id?.status == 2 && moment().isAfter(moment(orden.cotizacion_id?.fecha_respuesta))) {
        statusCotizacion = <div>
            <ExclamationCircleOutlined style={{ fontSize: 23, display: "block" }} />
            <Title level={5} style={{ display: "block" }}>Cotización Vencida</Title>
        </div>
    } else {
        statusCotizacion = <>
            <Button className='btn-opc-circle' title="Aceptar Cotizacion" onClick={() => props.showPago()}>
                <IconBtnOpcAceptar />
            </Button>
            <Button
                className='btn-opc-circle' title="Recotizar" onClick={() => props.openModal()}
            >   <IconBtnOpcExclamacion />
            </Button>
            <Button className='btn-opc-circle' title="Rechazar Cotizacion"
                onClick={(e) => { props.openModalRechazo() }}>
                <IconBtnOpcCancelar />
            </Button>
        </>
    }
    return <>
        <Card
            className=" width-100 card-cotizacion"
        >
            <Row className='mb-1'>
                <Col span={22} >
                    <label className="tag-card-blue">{orden?.folio}</label>
                </Col>
            </Row>

            <Row className='mb-1 pl-1'>
                <Col xs={24} xxl={4} >
                    <Text>{orden?.cliente?.nombre}</Text>
                </Col>
                <Col xs={24} xxl={4} >
                    <Row>
                        <Col xs={24}>
                            <Text className="text-gray">Validez</Text>
                        </Col>
                        <Col xs={24}>
                            <Text>{orden?.cotizacion_id ? orden?.cotizacion_id?.vigencia : '-'} días</Text>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} xxl={4} >
                    <Row>
                        <Col xs={24}>

                            <Text className="text-gray">Monto Total</Text>
                        </Col>
                        <Col xs={24}>
                            <Text>$ {orden?.total.toMoney(true)} MXN </Text>
                        </Col>
                    </Row>

                </Col>
                <Col xs={24} xxl={4} className="center">
                    {orden.cotizacion_id?.status == 2 || orden.cotizacion_id?.status == 3 ? <a href={props.getPDF()} download target="_blank">Descargar PDF</a> : 'PDF no Disponible'}
                </Col>
                <Col xs={24} xxl={4} className="center">
                    {renderTag(orden.cotizacion_id?.status)}
                </Col>
                <Col xs={24} xxl={4} className="center" >
                    {statusCotizacion}
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <FlagFilled className="mr-1" /> {orden?.cotizacion_id?.fecha ? moment(orden?.cotizacion_id?.fecha).format("LLL") : 'Cotización aun no disponible'}
                </Col>
            </Row>
        </Card>

    </>
}

/**
 *
 * @name CardCustomerProjectManager
 * @description Card que presenta un resumen de costos y dias de credito en el Project Manager del Customer
 * @param {*} { monto_compras, facturas_pagadas, facturas_recibidas, facturas_pendientes, dias_credito }
 * @return {Card} 
 */
const CardCustomerProjectManager = ({ monto_compras, facturas_pagadas, facturas_recibidas, facturas_pendientes, dias_credito }) => {


    return <Card className="card-shadow card-account-purchases width-100" >
        <Row>
            <Col span={5}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Monto de Compras</Title>
                    </Col>
                    <Col span={24} className="center">
                        <Text className="card-monto-gray">$ {monto_compras.toMoney()} MXN</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={5}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Facturas Recibidas</Title>
                    </Col>
                    <Col span={24} className="center">
                        <Text className="card-monto-gray">$ {facturas_recibidas.toMoney()} MXN</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={5}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Facturas Pagadas</Title>
                    </Col>
                    <Col span={24} className="center">
                        <Text className="card-monto-gray">$ {facturas_pagadas.toMoney()} MXN</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={5}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Pendiente a Facturar</Title>
                    </Col>
                    <Col span={24} className="center">
                        <Text className="card-monto-gray">$ {facturas_pendientes.toMoney()} MXN</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={4}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Días de Credito</Title>
                    </Col>
                    <Col span={24} className="center">
                        <Text className="card-monto-gray">{dias_credito} Días</Text>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Card>
}

const CardCustomerPMCompras = ({ item, deleteItem, editItem, seeItemDetails }) => {
    return (
        <Card className="card-list card-customer-pm-compras">
            <div className={`badge-card`} style={{ background: "#006BFF" }}>  </div>
            <Row className="width-100">
                <Col xs={2} className="center mb-1">
                    <Text >{"{{Fecha}}"}</Text>
                </Col>
                <Col xs={4} className="center mb-1">
                    <Text className="text-gray-dark">{"{{ Producto }}"}</Text>
                </Col>
                <Col xs={4} className="center mb-1">
                    <Text className="text-gray-dark">{"{{ Cantidad }}"}</Text>
                </Col>
                <Col xs={4} className="center mb-1">
                    <Text strong >{`\${{ Monto }} MXN`}</Text>
                </Col>
                <Col xs={4} className="center mb-1">
                    <Text className="text-gray-dark">{"{{ Almacen }}"}</Text>
                </Col>
                <Col xs={4} className="center mb-1">
                    <Text className="text-gray-dark" strong>{"{{ DiasCredito }}"}</Text>
                </Col>
                <Col xs={2} className="center mb-1">
                    <Space>

                        <Button
                            icon={<FileDoneOutlined style={{ fontSize: "1.25rem", color: "#FFF" }} />}
                            type="primary"
                            title="Editar"
                            onClick={editItem}
                            style={{
                                backgroundColor: "#FF7E08",
                                borderColor: "#FF7E08"
                            }}
                        >
                        </Button>
                        <Button
                            icon={<EyeOutlined style={{ fontSize: "1.25rem", color: "#FFF" }} />}
                            type="primary"
                            title="Ver Detalles"
                            onClick={seeItemDetails}
                            style={{
                                backgroundColor: "#ADFF45",
                                borderColor: "#ADFF45"
                            }}
                        >
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Card>
    )
}

/**
 * @const CardTaskStep
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la informacion del step
 * @param String color Color del step
 */
const CardTaskStep = ({ title = "", position = "", color = "#bababa", almacen = false }) => {
    return <Card
        className="card-task-name"
        cover={<span className="card-task-color"
            style={{ backgroundColor: color }} />}
    >
        <Card.Meta
            className="card-task-name-meta"
            title={title || "Cotización"}
        // description={(almacen) ? position : `${position} Paso en el Proceso de Cadena Alimenticia.`} 

        />
    </Card>
}

/**
 * @const Navbar
 * @description Header del sistema
 * @param ObjectId id
 * @param Date fecha de la lista de task
 * @param String color del step
 * @param String description del task
 * @param Array Lista tasks
 * @param String Nombre del cliente
 * 
 */
const CardTaskClient = ({ id, vencido = false, sinAsesor = null, complete = false, cliente = {}, tipo, date = new Date(), description, task, color = "#bababa", orden = {}, onClick = () => { }, onCancel = () => { } }) => {


    let user = React.useContext(User)


    let dates = (now = moment(), date) => {

        let time_ago = 'min'
        let diff_ago = now.diff(moment(date), 'minutes')

        if (diff_ago > 120) {
            diff_ago = now.diff(moment(date), 'hours')
            time_ago = 'hrs';
            if (diff_ago > 23) {
                diff_ago = now.diff(moment(date), 'days');
                time_ago = 'days';
            }
        }


        return <>
            {diff_ago} {time_ago}
        </>
    }



    let asesorInfo = orden.agente_compras_id ?

        <span className="ml-1"><CustomAvatar
            image={orden.agente_compras_id.avatar}
            name={[orden.agente_compras_id?.nombre, orden.agente_compras_id?.apellido]} /> {orden.agente_compras_id?.nombre} {orden.agente_compras_id?.apellido}</span>
        : <span> Sin asesor asignado </span>

    // console.log('orden', orden);

    return <Card
        title={<a onClick={onClick}>{orden.folio}</a>}
        hoverable
        extra={<Tag onClick={onClick} className="card-task-tag" color={color} style={{ color: vencido ? "black" : undefined }} >{dates(moment(), orden.createdAt)}</Tag>}
        bordered={false}
        // complete
        className={"card-task pm " + (complete ? " card-task-complete" : "")}
        style={{ position: "relative", }}
        loading={(orden.loading === true)}
    >

        <div className='card-container-pm'>
            <div onClick={onClick}>
                <p className="card-task-description">
                    <strong>Cliente:</strong> {cliente.nombre ? cliente.nombre : '-'}<br />
                    {cliente.razon_social != undefined ? <> <strong>Razón Social:</strong> {cliente.razon_social} <br /></> : null}
                    {cliente.rfc != undefined ? <><strong>RFC:</strong> {cliente.rfc} <br /></> : null}
                    <strong>Email:</strong> {cliente.email ? cliente.email : '-'}
                    <br />
                    <br />
                    <strong>Creado por:</strong> {orden?.usuario?.nombre} {orden?.usuario?.apellido} ({orden?.usuario?.email})
                </p>
                <List
                    itemLayout="horizontal"
                    dataSource={task}
                    bordered={false}
                    className="list-border-none"
                    renderItem={item => (
                        <List.Item >
                            <List.Item.Meta
                                className="card-task-list-meta"
                                avatar={<Checkbox

                                    className="custom-checkbox-circle"
                                    checked={item.title == 'Liquidación del Cliente' ? orden.liquidado : item.value <= orden.estado}

                                />}
                                title={item.title}

                            />
                        </List.Item>
                    )}

                />
            </div>
        </div>
        <Row className='card-task-footer' justify={"space-between"}>
            <Col span={24}>
                <Tag 
                    style={{ marginTop: "1rem" }} 
                    className="card-task-tag-spot-vmi">
                    {orden.tipo_orden === 2 ? "VMI" : "Spot"}
                    </Tag>{vencido ? <Tag color="#EFF00C" style={{ color: "black", maxHeight: "30px" }} icon={<WarningOutlined style={{ color: "currentcolor" }} />}>Vencida hace {dates(moment(), orden.cotizacion_id.fecha_respuesta)}</Tag> : ((orden.estatus === 5) ? <Tag color="#F50" style={{ maxHeight: "30px" }} icon={<CloseOutlined style={{ color: "currentcolor" }} />}>Rechazada</Tag> : null)}
                <p style={{ margin: "1rem 0" }}>{moment(orden.createdAt).format('LL')}</p>
            </Col>
            <Col>
                {
                    (user?.tipo == 1) ? <Dropdown
                        trigger={"click"}
                        overlay={sinAsesor}
                        placement="bottomLeft" arrow>
                        {asesorInfo}
                    </Dropdown> : <p>asesorInfo</p>
                }
            </Col>
            <Col>
                <Popconfirm
                    title={"¿Cancelar orden?"}
                    onConfirm={() => onCancel()}
                    okText={"Sí"}
                    cancelText={"No"}
                >
                    <Button
                        className='btn-opc-circle btn-small'
                        title="Cancelar Orden"
                    >
                        <IconBtnOpcCancelar />
                    </Button>
                </Popconfirm>
            </Col>


            {/* {

            }
            {orden.agente_compras_id ? <>
            </> : <>
                {
                    user?.tipo === 1 ? <>
                        <Dropdown
                            trigger={"click"}
                            onClick={e => {
                                e.preventDefault()
                            }}
                            overlay={sinAsesor} placement="bottomLeft" arrow>
                            <Button
                                onClick={e => {
                                    e.preventDefault();
                                }}>Asignar Asesor</Button>
                        </Dropdown>
                    </> : 
                }
            </>} */}
        </Row>
    </Card>

}

/**
 * @const Navbar
 * @description Header del sistema
 * @param ObjectId id
 * @param Date fecha de la lista de productos
 * @param String color del step
* @param Array Lista productos
 * @param String Nombre del cliente
 * */
const CardClientProductos = ({ _id = "", nombre = "", almacenes = 0, productos = [], tipo = 1, color = "#bababa", className = "" }) => {

    return <Card
        key={nombre}
        title={nombre}
        hoverable
        extra={<Tag className="card-task-tag" color={color}>{almacenes} Almacenes</Tag>}
        bordered={false}
        className="card-task"

    >
        <List
            itemLayout="horizontal"
            dataSource={productos}
            bordered={false}
            className="list-border-none"
            locale={'Sin Almacenes'}
            style={{ height: "120px", overflowY: "auto", paddingLeft: "5px" }}
            renderItem={item => (
                <List.Item >
                    <List.Item.Meta
                        className="card-task-list-meta"
                        avatar={<Checkbox
                            className={`custom-checkbox-circle ${className}`}
                            checked={true}
                        />}
                        title={item.np_fabricante + " / " + item.np_nexus}
                    />
                </List.Item>
            )}
        />
        <Tag style={{ marginTop: "0.5rem" }} className="tag-spot-vmi">{tipo === 1 ? "VMI" : "Spot"}</Tag>

    </Card>


}

const CardIncomeExpense = ({ item, deleteItem, editItem, seeItemDetails }) => {
    return (
        <Card className="card-list">
            <div className={`badge-card tipo-${item.tipo}`}>  </div>
            <Row className="width-100">
                <Col xs={2} className="center mb-1">
                    <Text strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
                </Col>
                <Col xs={3} className="center mb-1">
                    <Text className="text-gray-dark">{item.concepto}</Text>
                </Col>
                <Col xs={3} className="center mb-1">
                    <Text className="text-gray-dark">{item.area_id?.nombre}</Text>
                </Col>
                <Col xs={3} className="center mb-1">
                    <Text className="text-gray-dark">{item.rubro_id?.nombre}</Text>
                </Col>
                <Col xs={3} className="center mb-1">
                    <Text className="text-gray-dark">{item.orden_id?.folio}</Text>
                </Col>
                <Col xs={1} className="center mb-1">
                    <CustomAvatar name={[item.cliente_id?.nombre, item.cliente_id?.apellido]} />
                </Col>
                <Col xs={3} className="center mb-1">
                    <Text className="text-gray-dark">{item.cuenta_id?.nombre}</Text>
                </Col>
                <Col xs={3} className="center mb-1">
                    <span style={{ color: (item.tipo === 1 ? "#10A902" : "#FF0000"), fontWeight: "700", fontSize: "16px", fontFamily: "Poppins" }} > $ {item.monto.toMoney(true)} </span>
                </Col>
                <Col xs={2} className="center mb-1">
                    <Space>
                        <Button
                            icon={<IconDetails />}
                            type="primary"
                            title="Ver Detalles"
                            onClick={seeItemDetails}
                            style={{
                                backgroundColor: "#ADFF45",
                                borderColor: "#ADFF45"
                            }}
                        >
                        </Button>
                        <Button
                            icon={<IconEdit />}
                            type="primary"
                            title="Editar"
                            onClick={editItem}
                            style={{
                                backgroundColor: "#456EFF",
                                borderColor: "#456EFF"
                            }}
                        >
                        </Button>
                        <Popconfirm
                            placement="topRight"
                            title="¿Deseas eliminar este registro?"
                            okText="Si"
                            cancelText="No"
                            onConfirm={() => deleteItem(item._id)}
                        >
                            <Button type="primary" danger icon={<IconDelete />} title="Eliminar" />
                        </Popconfirm>
                    </Space>
                </Col>
            </Row>
        </Card>
    )
}

const CardFactura = ({ item, deleteItem, editItem, sendItem, seeItemDetails, aceptarOrden, }) => {
    return (
        <Card className="card-list">
            <Row className="width-100">
                <Col xs={2} className="center mb-1">
                    <Text strong>{moment(item.fecha_vigencia).format('DD/MM/YYYY')}</Text>
                </Col>
                <Col xs={4} className="center mb-1">
                    <Text className="text-gray-dark">{item.concepto}</Text>
                </Col>
                <Col xs={3} className="center mb-1">
                    <Text className="text-gray-dark">{
                        item.estatus === 0 ? "Rechazada"
                            : item.estatus === 1 ? "Cotización"
                                : item.estatus === 2 ? "Factura"
                                    : item.estatus === 3 ? "Transacción"
                                        : "Cancelada"
                    }</Text>
                </Col>
                <Col xs={3} className="center mb-1">
                    <Text className="text-gray-dark">{
                        item.estatus === -1 ? "Egreso"
                            : item.estatus === 0 ? "Sin Cambios"
                                : "Ingreso"
                    }</Text>
                </Col>
                <Col xs={3} className="center">
                    <Text className="text-gray-dark">{item.emisor?.nombre}</Text>
                </Col>
                <Col xs={3} className="center">
                    <Text className="text-gray-dark">{item.receptor?.nombre}</Text>
                </Col>
                <Col xs={2} className="center">
                    <Text className="text-gray-dark">{
                        item.monto ? "$" + item.monto.toMoney(true) : ""
                    }</Text>
                </Col>
                <Col xs={4} className="center">
                    <Space>
                        <Button
                            icon={<IconDetails />}
                            type="primary"
                            title="Ver Detalles"
                            onClick={seeItemDetails}
                            style={{
                                backgroundColor: "#ADFF45",
                                borderColor: "#ADFF45"
                            }}
                        >
                        </Button>
                        <Button
                            icon={<IconEdit />}
                            type="primary"
                            title="Editar"
                            onClick={editItem}
                            style={{
                                backgroundColor: "#456EFF",
                                borderColor: "#456EFF"
                            }}
                        >
                        </Button>
                        <Popconfirm
                            placement="topRight"
                            title="¿Deseas eliminar este registro?"
                            okText="Si"
                            cancelText="No"
                            onConfirm={() => deleteItem(item._id)}
                        >
                            <Button type="primary" danger icon={<IconDelete />} title="Eliminar" />
                        </Popconfirm>
                    </Space>
                </Col>
            </Row>
        </Card>
    )
}

const CardOrdenCompra = ({ item, deleteItem, editItem, aceptarOrden, sendItem, pdfOrden, premium, addAlmacen }) => {

    var estatus = "", color = "";

    // Estatus
    // 0 - Creada (No se ha aceptado)
    // 1 - Aceptada (Alguíen con permiso, le dió click en "Aceptar, la palomita")
    // 2 - Confirmada (El proveedor envio numero de guia de la orden)
    // 3 - Incompleta: (Recibió unos pocos materiales)
    // 4 - Completa: (Ya recibió todos los materiales)
    // 5 - En entrega: (Ya recibió todos los materiales)
    // 6 - Entregada: (Ya recibió todos los materiales)
    // 7 - Completa (Para sacar la lista de compras a considerar dentro de las consultas de datos pendientes)

    estatus = item.estatus === 0 ? "Creada"
        : item.estatus === 1 ? "Aceptada"
            : item.estatus === 2 ? "Confirmada"
                : item.estatus === 3 ? "Incompleta"
                    : item.estatus === 4 ? "Completa"
                        : item.estatus === 5 ? "En entrega"
                            : item.estatus === 6 ? "Entregada"
                                : item.estatus === 7 ? "Completa"


                                    : "N/A";

    return (
        <Card className="card-list">
            <Row className="width-100">

                {/* Fecha */}
                <Col xs={6} xxl={2} className="center mb-1">
                    <Text strong>{moment(item.fecha_vigencia).format('DD/MM/YYYY')}</Text>
                </Col>

                {/* Proveedor */}
                <Col xs={6} xxl={5} className="center mb-1">
                    <Text className="text-gray-dark">{item.proveedor?.nombre}</Text>
                </Col>

                {/* Estatus */}
                <Col xs={6} xxl={3} className="center mb-1">
                    <Text style={{ minWidth: "80px", textAlign: "center", background: "#C4C4C4", color: "#222222", padding: "5px  15px", borderRadius: "20px" }}>{estatus}</Text>
                </Col>

                {/* Destinatario */}
                <Col xs={6} xxl={4} className="center mb-1">
                    <Text style={{ minWidth: "140px", textAlign: "center", background: "#deddf9", color: "#5C54E0", padding: "5px  15px", borderRadius: "20px" }}>{"Proveedor"}</Text>
                </Col>

                {/* Monto total */}
                <Col xs={8} xxl={3} className="center ">
                    <Text className="text-gray-dark">{item.monto_total ? "$" + (item.monto_total).toMoney() : "0"}</Text>
                </Col>

                {/* Monto pagado */}
                <Col xs={8} xxl={3} className="center ">
                    <Text className="text-gray-dark">{item.monto_pagado ? "$" + (item.monto_pagado).toMoney() : "0"}</Text>
                </Col>

                {/* Acciones */}
                <Col xs={8} xxl={3} className="center ">
                    <Space>

                        <Button
                            icon={<IconMail />}
                            type="primary"
                            title="Enviar"
                            onClick={sendItem}
                            style={{
                                backgroundColor: "#B845FF",
                                borderColor: "#B845FF"
                            }}
                        >
                        </Button>
                        {(item.estatus === 0 && (item.almacen_id || !premium)) ?
                            <Button
                                icon={<IconAceptarOrden />}
                                type="primary"
                                title="Aceptar"
                                onClick={aceptarOrden}
                                style={{
                                    backgroundColor: "#FFCB45",
                                    borderColor: "#FFCB45"
                                }}
                            >
                            </Button>
                            : null}
                        {(item.almacen_id || !premium) ?
                            <Button
                                icon={<IconPDF />}
                                type="primary"
                                title="PDF"
                                onClick={pdfOrden}
                                style={{
                                    backgroundColor: "#54FFD6",
                                    borderColor: "#54FFD6",
                                }}
                            >
                            </Button>
                            : null}
                        {(!item.almacen_id && premium) ?
                            <Button
                                icon={<WarningOutlined style={{ color: "#FFF" }} />}
                                type="primary"
                                title="Agregar Almacen"
                                onClick={addAlmacen}
                            />
                            : null}


                        {/* <Button
                            icon={<IconEdit />}
                            type="primary"
                            title="Editar"
                            onClick={editItem}
                            style={{
                                backgroundColor: "#456EFF",
                                borderColor: "#456EFF"
                            }}
                        >
                        </Button>
                        <Popconfirm
                            placement="topRight"
                            title="¿Deseas eliminar este registro?"
                            okText="Si"
                            cancelText="No"
                            onConfirm={() => deleteItem(item._id)}
                        >
                            <Button type="primary" danger icon={<IconDelete />} title="Eliminar" />
                        </Popconfirm> */}
                    </Space>
                </Col>
            </Row>
        </Card>
    )
}

/**
 * @const CardSaldo
 * @description Card que muetra el total de Saldo diponible entre todas las cuentas
 * @param num saldo suma de los saldos disponibles
 * @param num cuentas cantidad de cuentas registradas
 */
const CardSaldo = ({ saldo = 0, cuentas = 0 }) => {
    return <Card className="card-saldo">
        <Card.Meta
            className="card-saldo-main-meta"
            title="Saldo al Momento"
            description={<><Text style={{ marginRight: '0.5rem' }}>$ {saldo.toMoney()}</Text><small>MXN</small></>} />
        <Card.Meta
            className="card-saldo-sub-meta"
            title="Cuentas Incluidas"
            description={<>{cuentas}</>} />
    </Card>
}

/**
 * @const CardCuenta
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
const CardCuenta = ({ cuenta = { saldo: 0 } }) => {

    let logos = {
        1: <IconMaster />,
        2: <IconVisa />,
        3: <img src={'/images/AE.png'} alt="AE" height="30px" />
    }

    return <Card bordered={false} className="card-cuenta" style={{ background: `linear-gradient(244.64deg, ${LightenDarkenColor(cuenta.color ? cuenta.color : "#87d068", -20)} 10%, ${LightenDarkenColor(cuenta.color ? cuenta.color : "#87d068", 20)} 49.92%, ${LightenDarkenColor(cuenta.color, -10)}  85.62%)` }}>
        <Row>
            <Col span={4} className="center">
                <CustomAvatar name={[cuenta.nombre, cuenta.apellido]} image={cuenta.logo} color={cuenta.color} />
            </Col>
            <Col span={14} className="flex-left ">
                <Text className="cuenta-nombre">{cuenta.nombre} <IconCheckMedal /></Text>
            </Col>
            <Col span={6} className="center">
                {cuenta.syncfy_credenciales_id && cuenta.syncfy_cuenta_bancaria_id ? <Link to={`/admin/finanzas/transacciones/sin-clasificar/${cuenta._id}`}><Button className="btn-purple" icon={<IconFinanzas />} shape="circle"></Button></Link> : null}
                {/*<Button icon={<IconFinanzas/>} shape="circle"></Button>*/}
            </Col>
        </Row>
        <Card.Meta
            className="card-saldo-sub-meta"
            title={<Row justify="space-between">
                <Col><Text style={{ marginRight: '0.5rem' }} className="text-white">$ {cuenta.saldo?.toMoney()}</Text><small>MXN</small></Col>
                <Col> <Image preview={false} src={"/images/Chip.png"} /></Col>
            </Row>}
            description={cuenta.syncfy_saldo ? <><Text style={{ marginRight: '0.5rem' }} className="text-white">$ {cuenta.syncfy_saldo?.toMoney()}</Text><small>MXN</small></> : <><Text style={{ marginRight: '0.5rem' }} className="text-white"></Text></>} />
        <Row>
            <Col span={8} className="flex-left">
                <small className="text-white">ACCOUNT NUMBER</small>
            </Col>
            <Col span={12} className="flex-left">
                <Text strong style={{ paddingLeft: '7px' }} className="text-white">{cuenta.cuenta}</Text>

            </Col>
            <Col span={4} className="center">
                {logos[cuenta.marca]}
            </Col>
        </Row>
    </Card>
}


/**
 * @const CardProfit
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
const CardGains = ({ title = "Ingresos", status = 'success' }) => {
    return <Card className="card-gains card-border-16">
        <Row align='middle' justify='center'>
            <Col span={4} lg={4}>
                <div className={`${title === 'Profit' ? 'green-box' : 'red-box'}`}>
                    {title === 'Profit' ? <IconProfit /> : <IconLoss />}
                </div>
            </Col>
            <Col span={16} lg={16} >
                <Text className="card-title center">{title}</Text>
            </Col>
            <Col span={4} lg={4} className=" dots">
                <Button ghost icon={<IconMenuDots />}></Button>
            </Col>
        </Row>
        <Row gutter={[8, 8]}>
            <Col span={24} className="center">
                <Text>{moment().format('DD/MM/YYYY')}</Text>
            </Col>
            <Col span={16} className="flex-left">
                <Text className="text-paid">Monto Pagado</Text>
            </Col>
            <Col span={8} className="flex-right">
                <Text className="text-paid">Total</Text>
            </Col>
            <Col span={24} className="center">
                <Progress percent={90} status={status} showInfo={false} />
            </Col>
        </Row>
        <Row>
            <Col span={24} className="center">
                <Text>{moment().format('DD/MM/YYYY')}</Text>
            </Col>
            <Col span={16} className="flex-left">
                <Text className="text-paid">Monto Pagado</Text>
            </Col>
            <Col span={8} className="flex-right">
                <Text className="text-paid">Total</Text>
            </Col>
            <Col span={24} className="center">
                <Progress percent={100} status={status} showInfo={false} />
            </Col>

        </Row>

    </Card>
}

/**
 *
 * @const CardProducto
 * @param {*} {producto, onEditClick, onDeleteClick} objeto y funciones para manejar la informacion del producto
 * @description Card del producto con la informacion para cotizar en marketplace
 */
const CardProducto = ({ producto, onEditClick, onDeleteClick }) => {

    return (
        <Card
            className="card-producto"
            style={{ width: "100%" }}
        >
            <Row justify="space-between" style={{ width: "100%" }}>
                {
                    producto.imagen ? (
                        <Col xs={24} lg={4} style={{ marginRight: "1rem" }}>
                            <img
                                src={`${producto.imagen[0].thumbUrl || "ni idea"}`}
                                alt=""
                                className="card-producto-thumb"
                            />
                        </Col>
                    ) : null
                }
                <Col xs={24} lg={producto.imagen ? 19 : 24} className='card-producto-body' >
                    <Row justify="space-between">
                        <Col>
                            <Space size={16}>
                                {
                                    producto.marca ? (
                                        <Text>
                                            {producto.marca ?? "Marca N/A"}
                                        </Text>
                                    ) : null
                                }
                                {(producto.proveedor?._id) ? <Text>{producto.proveedor?.nombre}</Text> : null}
                                <Text>
                                    {producto.modelo ?? "Modelo N/A"} {producto.sku ?? "SKU N/A"}
                                </Text>
                            </Space>
                        </Col>
                        <Col>
                            {onEditClick ? <Button className="card-producto-button" onClick={onEditClick}>
                                <EditFilled style={{
                                    fontSize: "1rem"
                                }} />
                            </Button> : null}

                            {onDeleteClick ? <Button className="card-producto-button" onClick={onDeleteClick}>
                                <DeleteFilled style={{
                                    fontSize: "1rem"
                                }} />
                            </Button> : null}
                        </Col>
                    </Row>
                    <Row justify="space-between" style={{ flexWrap: "nowrap", marginTop: "0.5rem" }}>
                        <Col style={{ flexGrow: 1, paddingRight: "2rem" }}>
                            <Text style={{ fontSize: "0.75rem", maxWidth: 600, display: "block" }}>
                                {
                                    producto.descripcion ? (
                                        producto.descripcion.slice(0, 200) + " ..."
                                    ) : ("Descripción no disponible")
                                }
                            </Text>
                        </Col>
                        <Col className='card-producto-numbers'>
                            <Text style={{ fontSize: "0.75rem", display: "block" }}>
                                {
                                    producto.um ?
                                        `${producto.cantidad} ${producto.um}`
                                        : null
                                }
                            </Text>
                            <Text style={{ fontSize: "0.75rem", display: "block" }}>
                                {
                                    producto.precio_ref ?
                                        `$${producto.precio_ref.toMoney(true)}`
                                        : ""
                                }
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}

/**
 * @const CardSaldosGraph
 * @description Card de saldos de las cuentas
 * @param Array data Lista de cuentas con saldos
 * @param String title Nombre de la grafica
 */
const CardSaldosGraph = ({ title = "Saldos", data = [], total = 0, tipo_cambio }) => {


    let dataSaldos = data;

    let series = []
    dataSaldos.map(item => {
        series.push({
            name: item.name,
            type: 'bar',
            stack: '1',
            label: {
                show: false,
            },
            barWidth: 15,
            data: [item.value],
        });
    });


    return <Card className="card-saldos-dashboard" bordered={false} title={title}>
        {
            series.length < 1 ? (
                <Empty description="No hay datos" className='pb-2' />
            ) : (
                <ReactEcharts
                    style={{ height: '260px', maxWidth: '480px', paddingTop: "0px" }}
                    theme="light"
                    className="pd-1"
                    option={{
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow',
                            }
                        },
                        title: {
                            subtext:`$ ${total.toMoney(true)} mxn / ${total.toDolar(tipo_cambio)} usd`,
                            subtextStyle: {
                                align: 'center',
                                fontSize: 14,
                                color: '#000',
                                fontWeight: 'bold'
                            }
                        },
                        legend: {
                            data: dataSaldos,
                            left: 'left',
                            bottom: 0,
                            itemWidth: 10,
                            itemHeight: 10,
                            orient: 'vertical',
                            textStyle: {
                                align: 'right',
                                fontSize: 14,
                                fontWeight: "normal",
                                lineHeight: 15,
                                color: '#858997',

                            },
                            lineStyle: {
                                cap: 'round'
                            },
                            formatter: function (name) {
                                let info = ""
                                for (let index = 0; index < dataSaldos.length; index++) {
                                    const element = dataSaldos[index]
                                    if (element.name == name) {
                                        const val = element.value.toMoney(true);
                                        info = name + ' :  $' + val + " mxn / " + element.value.toDolar() + " usd"
                                        break
                                    }
                                }
                                return info
                            }
                        },
                        grid: {
                            left: '2%',
                            right: '2%',
                            bottom: '0px',
                            top: '70px',
                            height: '10px',
                            innerWidth: '100px',
                            containLabel: false,
                            borderRadius: "20px"
                        },
                        xAxis: {
                            type: 'value',
                            show: false,
                            splitLine: {
                                show: false
                            }
                        },
                        yAxis: {
                            type: 'category',
                            show: false,
                            splitLine: {
                                show: false
                            },
                        },
                        series: series
                    }}
                />
            )
        }
    </Card>

}

/**
 * @const CardOperations
 * @description Card de saldos de las cuentas
 * @param String title Nombre del card
 * @param operation Object con la informacion del card
 * @param onClick Funcion a ejecutar al hacer click
 * @param color Color del ProgressBar y el Icono,
 * @param String icon Si el icono es flecha arriba o flecha abajo
 */
const CardOperations = ({ total = 0, total_mp = 0, title = "", progress = false, color = "#006BFF", icon = "down", tipo_cambio, getTransacciones = ()=>{}, tipo = "Transacciones" }) => {

    if (total > total_mp) {
        icon = "up";
        color = "#006BFF"
    }
    else {
        icon = "down";
        color = "#006BFF"
    }

    var diferencia = total - total_mp;

    var porcentaje = 100;

    if (total_mp > 0) {
        porcentaje = (diferencia * 100) / total_mp;
    }


    return <Card bordered={false} className="card-operations-dashboard " title={title}>
        <Card.Meta
            title={<> ${total.toMoney(true)} <small>mxn</small> / {total.toDolar(tipo_cambio)} <small>usd</small> <span className={icon == "up" ? "operations-up" : "operations-down"}>{icon == "up" ? <ArrowUpOutlined /> : <ArrowDownOutlined />} {porcentaje.toMoney(true)} %</span></>}
            description={"Contra los  $" + (total_mp.toMoney(true)) + " mxn  / " + (total_mp.toDolar(tipo_cambio)) + " usd del mes pasado"}
        />
        <Card size="small" className="card-operations-dashboard-inner pd-1">
            <Row className="width-100">
                <Col span={24}>
                    <Button 
                        type="primary"
                        onClick={()=> getTransacciones()}
                        icon={<FileExcelOutlined style={{color: "white"}}/>}
                        style={{marginRight: "4px"}}
                        title={tipo + " mensuales"}
                    />
                    <Text>Exportar {tipo} del mes</Text>
                </Col>
                <Col span={24}>
                    <Row className="width-100">
                        <Col span={24}><p className="card-operations-dashboard-inner-title  ">Costos de Venta</p></Col>
                        <Col span={24}>
                            <Row align="middle" justify="space-between" className="width-100">
                                <Col span={4}><p className="card-operations-dashboard-progress-text">Total</p></Col>
                            </Row>
                            {progress ? <Progress percent={porcentaje} showInfo={false} strokeColor={color} /> : null}
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Card>
    </Card>
}

/**
 * @const CardOperationsList
 * @description Card de saldos de las cuentas
 * @param String title Nombre del card
 * @param operation Object con la informacion del card
 * @param onClick Funcion a ejecutar al hacer click
 * @param color Color del ProgressBar y el Icono,
 * @param String icon Si el icono es flecha arriba o flecha abajo
 */
const CardOperationsList = ({ total = 0, total_mp = 0, title = "", dataTop = [], operation = {}, onClick = () => { }, color = "#006BFF", icon = "down" , tipo_cambio}) => {


    if (total > total_mp) {
        icon = "up";
        color = "#006BFF"
    }
    else {
        icon = "down";
        color = "#006BFF"
    }

    var diferencia = total - total_mp;


    var porcentaje = 100;

    if (total_mp > 0) {
        porcentaje = (diferencia * 100) / total_mp;
    }



    return <Card bordered={false} className="card-operations-dashboard " title={title}>
        <Card.Meta
            title={<> $ {total.toMoney(true)} <small>mxn</small> / {total.toDolar(tipo_cambio)} usd <span className={icon == "up" ? "operations-up" : "operations-down"}>{icon == "up" ? <ArrowUpOutlined /> : <ArrowDownOutlined />}  {porcentaje.toMoney(true)} %</span></>}
            description={"Contra los $" + (total_mp.toMoney(true)) + " mxn / " + (total_mp.toDolar(tipo_cambio)) + " usd del mes pasado"}
        />
        <List
            size="small"
            className="list-border-none list-top-dashboard"
            header={<div className="top-list-header ">Gastos</div>}
            bordered={false}
            dataSource={dataTop}
            renderItem={item => <List.Item>
                <List.Item.Meta
                    avatar={<Avatar shape="square" size="small" style={{ backgroundColor: item.color }} />}
                    title={item.nombre}
                />

                <div className="list-top-dashboard-monto">$ {item.monto.toMoney(true)} mxn / {item.monto.toDolar(tipo_cambio)} usd</div>
            </List.Item>}
        />
    </Card>
}

/**
 * @const CardTaskProgress
 * @description Card de saldos de las cuentas
 * @param color String con el color del progress bar 
 * @param task Object con la informacion del card
 */
const CardTaskProgress = ({ color = "#46BD84", percent = 0, started = null, title = "Progreso de Tarea" } = {}) => {
    return <Card
        className="card-task-progress card-shadow card-medium-height"
        title={title}>
        <Card.Meta description={(started !== null) ? (`Inicio ${moment(started).fromNow()}`) : null} />
        <Row align="middle" justify="center" className="mt-1">
            <Col className="block-center">
                <Progress type="circle" percent={percent?.toMoney()} width={150} strokeColor={color} />
            </Col>
        </Row>
    </Card>
}

/**
 * @const CardOrdenProgress
 * @description Card de saldos de las cuentas
 * @param color String con el color del progress bar 
 * @param task Object con la informacion del card
 */
const CardOrdenProgress = ({ color = "#46BD84", percent = 0, started = null } = {}) => {
    return <Card
        className="card-task-progress card-shadow card-medium-height"
        title="Progreso Orden">
        <Card.Meta description={(started !== null) ? (`Started ${moment(started).fromNow()}`) : null} />
        <Row align="middle" justify="center">
            <Col className="block-center">
                <Progress type="circle" percent={percent?.toMoney()} width={150} strokeColor={color} />
            </Col>
        </Row>
    </Card>
}

/**
 * @const CardProjectInfo
 * @description Card de saldos de las cuentas
 * @param users Array de Usuarios
 * @param project Object con la informacion del projecto
 * @param color Color del ProgressBar 
 */


const CardProjectInfo = (props) => {


    const history = useHistory();

    const { cliente, orden, progress = 0, color = "#46BD84", responsable } = props

    const avatar_props = {
        image: responsable?.avatar,
        name: `${responsable ? responsable.nombre : 'N'} ${responsable ? responsable.apellido : 'A'}`
    }

    /**
     *
     *
     * @memberof CardProjectInfo
     * @method cancelOrden
     * @description Cambia el estatus de la orden a cancelado y te regresa al PM
     */
    const cancelOrden = () => axios.post("/ordenes/update", {
        id: orden._id,
        estatus: 2
    }, {
        headers: { Authorization: sessionStorage.getItem('token') }
    })
        .then(response => {
            message.success('Se ha cancelado la orden');
            history.push('/admin/project-manager')
        })
        .catch(err => {
            message.error('Error al cancelar la orden');
        })

    return <Card
        title={orden?.folio ? orden?.folio : '-'}
        className="card-project card-shadow card-medium-height"
        extra={
            <Button
                className='btn-opc-circle btn-small'
                title="Cancelar Orden"

                onClick={() => Modal.confirm({

                    title: "¿Deseas cancelar la orden?",
                    onOk: cancelOrden
                })}
            >
                <IconBtnOpcCancelar />
            </Button>
        }
    >
        <Card.Meta
            description={<p><FlagFilled /> <span>{moment(orden?.createdAt).fromNow(true)}</span> <small>({moment(orden?.createdAt).format("LLL")})</small></p>}
        />
        <Row className="pb-1 ">
            <Col span={24} className="card-project-info">{cliente?.nombre}</Col>
            <Col span={24} className="card-project-info">{cliente?.razon_social}</Col>
            <Col span={24} className="card-project-info">{cliente?.telefono} /<span>{cliente?.email}</span></Col>
        </Row>
        <Row>
            <Col span={24}>
                <Row align="middle" justify="space-between" className="width-100">
                    <Col span={20}><p className="card-operations-dashboard-progress-text">Progreso de Cotización</p></Col>
                    <Col span={4}><p className="card-operations-dashboard-progress-text">{(typeof progress === "number") ? progress.toMoney() : progress} %</p></Col>
                </Row>
                <Progress percent={progress} showInfo={false} strokeColor={color} />
            </Col>
        </Row>
        <Row className="flex-left mt-05">
            <CustomAvatar {...avatar_props} style={{ backgroundColor: '#1890ff' }} icon={<AntDesignOutlined />} />
            <Text className="ml-1">{avatar_props.name}</Text>
        </Row>

    </Card>
}


/**
 * @const CardProjectInfo
 * @description Card de saldos de las cuentas
 * @param client Object del Cliente
 */
const CardClientInfo = ({ cliente = {} }) => {
    return <Card
        title={

            <Link to={"/admin/clientes/informacion/" + cliente?._id}>
                {cliente?.razon_social} <Button size="small" type="dashed" style={{ marginLeft: "4px" }}>Ir al Cliente</Button>
            </Link>

        } className="card-project card-shadow card-medium-small">
        <Card.Meta
            description={<p><FlagFilled /><span style={{ marginLeft: "0.5rem" }}>Clientes desde {moment(cliente?.createdAt).fromNow(true)}</span></p>}
        />
        <Row className="pb-1 ">
            <Col span={24} className="card-project-info">
                <Link to={"/admin/clientes/informacion/" + cliente?._id}>{cliente?.razon_social}</Link>
            </Col>
            <Col span={24} className="card-project-info">{cliente?.rfc}</Col>
            <Col span={24} className="card-project-info">{cliente?.telefono} /<span>{cliente?.email}</span></Col>
        </Row>
    </Card>
}

/**
 * @const CardEntrega
 * @description Card con los datos de Entrega del ProjectManager
 * @param direccion String direccion de entrega del producto
 */
const CardEntrega = ({ direccion = "", motivos = [], onClick = () => { }, orden_id = null, fecha_entrega = null, update = () => { } }) => {


    const onChange = (date, dateString) => {
        axios.post('/project-manager/entrega', { fecha_entrega: date, orden_id: orden_id })
            .then(res => {
                message.success('Fecha Entrega Asignado')
                update()
            })
            .catch(error => {
                message.error('Ocurrio un error')
            })
    }


    return <Card
        className="card-address card-shadow card-medium-height"
        title={<label className="tag-card-blue">Lugar de Entrega</label>}
    >
        <p className="mt-1">{direccion}</p>
        {motivos.length > 0 ? <Button className="mt-3" type="primary" onClick={onClick}>Ver Motivos Re-cotizacion</Button> : ''}
        <br />
        <br></br>
        <DatePicker placeholder='Seleccionar Fecha Entrega' defaultValue={fecha_entrega ? moment(fecha_entrega) : ''} value={fecha_entrega ? moment(fecha_entrega) : ''} format="YYYY-MM-DD" onChange={onChange} className="width-100" />
    </Card>
}

/**
 * @const CardUtilidad
 * @description  Card con la informacion de la utilidad
 * @param utility Integer con la utilidad en porcentaje
 */
const CardUtilidad = ({ color = "#D459E8", margen = 10 }) => {
    return <Card title="Margen de Utilidad" className="card-utilidad card-shadow card-small-height">
        <Row className="pb-1 ">
            <Col span={24}>
                <Row align="middle" justify="space-between" className="width-100">
                    <Col span={20}><p className="card-operations-dashboard-progress-text">Utilidad</p></Col>
                    <Col span={4}><p className="card-operations-dashboard-progress-text">{margen}%</p></Col>
                </Row>
                <Progress percent={margen} showInfo={false} strokeColor={color} />
            </Col>
        </Row>
    </Card>
}

/**
 * @const CardLegalInfo
 * @description  Card con la informacion legal
 */
function CardLegalInfo(props) {

    const { orden_id } = props

    const [orden, setOrden] = useState({})

    const [modalVigenciaVisible, setModalVigenciaVisible] = useState(false)
    const [tipo, setTipo] = useState(1)


    useEffect(() => {
        getOrden()
    }, [orden_id])


    const getOrden = () => {
        if (orden_id !== undefined) {
            axios.get('/ordenes/get', {
                params: {
                    id: orden_id
                },
                headers: { Authorization: sessionStorage.getItem('token') }
            })
                .then(({ data }) => {
                    setOrden(data.data)
                })
                .catch(error => {
                    console.log('error', error)
                })
        }
    }



    return <Card className="card-shadow card-small-height padding-sm">
        <Row justify="space-between" align="bottom" className="mb-05">
            <Col span={24}>
                <Row className="mb-05">
                    <Col span={23}>
                        <label className="tag-card-blue">Términos y Condiciones</label>
                    </Col>
                </Row>
                <Row>
                    <Col span={23}>
                        {orden?.cotizacion_id?.terms ? orden?.cotizacion_id?.terms.length : 0} términos
                    </Col>
                    <Col span={1}>
                        <FormOutlined onClick={() => {
                            setModalVigenciaVisible(true)
                            setTipo(1)
                        }} />
                    </Col>

                </Row>
            </Col>
        </Row>
        <Row justify="space-between" align="bottom" className="mb-05">
            <Col span={24}>
                <Row className="mb-05">
                    <Col span={23}>
                        <label className="tag-card-blue">Vigencia</label>
                    </Col>
                </Row>
                <Row>
                    {/* modalVigenciaVisible, setModalVigenciaVisible */}
                    <Col span={23}>
                        {orden?.cotizacion_id?.vigencia} Dias
                    </Col>
                    <Col span={1}>
                        <FormOutlined onClick={() => {
                            setModalVigenciaVisible(true)
                            setTipo(2)
                        }} />
                    </Col>
                </Row>
            </Col>
        </Row>
        {/* modalVigenciaVisible, setModalVigenciaVisible */}
        <ModalEditarVigencia
            orden_id={orden_id}
            tipo={tipo}
            visible={modalVigenciaVisible}
            onClose={() => {
                setModalVigenciaVisible(false)
                getOrden()
            }}
        />
    </Card>

}

const CardAccountPurchases = ({ vmi, monto_venta, costos_proyectado, costos_real, ingreso_proyectado, ingreso_real, utilidad_proyectado, utilidad_real, facturas_emitidas, facturas_recibidas, impuestos = 0 }) => {
    return <Card className="card-shadow card-account-purchases width-100" >
        <Row>
            <Col span={5}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Monto de Venta</Title>
                    </Col>
                    <Col span={24} className="center">
                        <Text className="card-monto-gray mt-1">$ {monto_venta.toMoney(true)} MXN</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={5}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Costo</Title>
                    </Col>
                    <Col span={24} className="center">
                        <Popover placement="topLeft" content={"Costo Real"} >
                            <Text className={`card-monto-gray ${vmi ? "mt-1" : ""}`}>$ {costos_real.toMoney(true)} MXN</Text>
                        </Popover>
                    </Col>

                    {
                        !vmi ? (
                            <Col span={24} className="center">
                                <Popover placement="topLeft" content={" Costo Proyectado "} >
                                    {/* <Text className="card-monto-gray">$ {costos_proyectado.toMoney(true)} MXN</Text> */}
                                    <Text className={`card-monto-gray`}>$ {ingreso_proyectado.toMoney(true)} MXN</Text>
                                </Popover>
                            </Col>
                        ) : null
                    }
                </Row>
            </Col>
            <Col span={5}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Ingresos</Title>
                    </Col>


                    <Col span={24} className="center">
                        <Popover placement="topLeft" content={"Ingreso Real"} >
                            <Text className={`card-monto-gray ${vmi ? "mt-1" : ""}`}>$ {ingreso_real.toMoney(true)} MXN</Text>
                        </Popover>
                    </Col>


                    {
                        !vmi ? (
                            <Col span={24} className="center">
                                <Popover placement="topLeft" content={"Ingreso Proyectado "} >
                                    {/* <Text className="card-monto-gray">$ {ingreso_proyectado.toMoney(true)} MXN</Text> */}
                                    <Text className={`card-monto-gray`}>$ {costos_proyectado.toMoney(true)} MXN</Text>
                                </Popover>
                            </Col>
                        ) : null
                    }

                </Row>
            </Col>
            <Col span={5}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Utilidad</Title>
                    </Col>

                    <Col span={24} className="center">
                        <Popover placement="topLeft" content={"Utilidad Real"} >
                            <Text className={`card-monto-gray ${vmi ? "mt-1" : ""}`}>$ {utilidad_real.toMoney(true)} MXN</Text>
                        </Popover>
                    </Col>

                    {
                        !vmi ? (
                            <Col span={24} className="center">
                                <Popover placement="topLeft" content={"Utilidad Proyectada "} >
                                    <Text className="card-monto-gray  ">$ {utilidad_proyectado.toMoney(true)} MXN</Text>
                                </Popover>
                            </Col>
                        ) : null
                    }
                </Row>
            </Col>
            <Col span={4}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Impuestos</Title>
                    </Col>
                    <Col span={24} className="center">
                        <Popover placement="topLeft" content={"Impuestos"} >
                            <Text className="card-monto-gray  mt-1">$ {impuestos.toMoney(true)} MXN</Text>
                        </Popover>
                    </Col>
                </Row>
            </Col>
            <Col span={5}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Facturas Emitidas</Title>
                    </Col>
                    <Col span={24} className="center">
                        <Text className="card-monto-gray  mt-1">$ {facturas_emitidas.toMoney(true)} MXN</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={5}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Facturas Recibidas</Title>
                    </Col>
                    <Col span={24} className="center">
                        <Text className="card-monto-gray  mt-1">$ {facturas_recibidas.toMoney(true)} MXN</Text>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Card>
}

const CardVMIInfo = ({ monto_venta = 0, facturas_emitidas = 0, facturas_cobradas = 0, facturas_pendientes = 0, dias_credito = 0, responsable }) => {
    return <Card
        className="card-shadow card-account-purchases width-100" >
        <Row>
            <Col xs={24} sm={12} lg={4}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Monto de Venta</Title>
                    </Col>
                    <Col span={24} className="center">
                        <Text className="card-monto-gray">$ {monto_venta.toMoney(true)} MXN</Text>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={12} lg={4}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Facturas Emitidas</Title>
                    </Col>
                    <Col span={24} className="center">
                        <Text className="card-monto-gray">$ {facturas_emitidas.toMoney(true)} MXN</Text>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={12} lg={4}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Facturas Cobradas</Title>
                    </Col>
                    <Col span={24} className="center">
                        <Text className="card-monto-gray">$ {facturas_cobradas.toMoney(true)} MXN</Text>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={12} lg={4}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Pendiente a Facturar</Title>
                    </Col>
                    <Col span={24} className="center">
                        <Text className="card-monto-gray">$ {facturas_pendientes.toMoney(true)} MXN</Text>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={12} lg={4}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Días de Credito</Title>
                    </Col>
                    <Col span={24} className="center">
                        <Text className="card-monto-gray">{dias_credito} días </Text>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={12} lg={4}>
                <Row justify="center">
                    <Col span={24}>
                        <Title className="card-title-purple" level={5}>Responsable</Title>
                    </Col>
                    <Col span={24} className="center">
                        <CustomAvatar
                            name={[responsable?.nombre, responsable?.apellido]}
                            className='mr-1'
                        />
                        {responsable?.nombre} {responsable?.apellido}
                    </Col>
                </Row>
            </Col>
        </Row>
    </Card>
}

/**
 * @const CardProductEstatus
 * @description  Card con la informacion de las ventas
 * @param productos Array de cantidad de productos por estatus
 */
const CardProductEstatus = ({ art1 = 0, art2 = 0, art3 = 0, products = {} }) => {
    return <Card title="Estatus Productos" className="card-project card-shadow card-medium-small">
        <Row align="middle" justify="space-between" >
            <Col span={2}><CloseCircleFilled className="text-red" /></Col>
            <Col span={17} className="card-estatus-text text-red ">Debajo del mínimo</Col>
            <Col span={5} className="card-estatus-cantidad">{art1}</Col>
        </Row>
        <Row align="middle" justify="space-around">
            <Col span={2}><WarningFilled className="text-yellow" /></Col>
            <Col span={17} className="card-estatus-text  text-yellow">Mínimo</Col>
            <Col span={5} className="card-estatus-cantidad">{art2}</Col>

        </Row>
        <Row align="middle" justify="space-around">
            <Col span={2}><CheckCircleFilled className="text-green" /></Col>
            <Col span={17} className="card-estatus-text text-green ">Min - Max </Col>
            <Col span={5} className="card-estatus-cantidad">{art3}</Col>
        </Row>
    </Card>
}

/**
 * @const CardProductsVentas
 * @description Card con los datos de Entrega del ProjectManager
 * @param direccion String direccion de entrega del producto
 */
const CardProductsVentas = ({ venta_dia = 0, venta_mes = 0, direccion = "" }) => {
    return <Card
        className="card-project card-shadow card-medium-small"
        title="Venta del Día"
    >
        <Row align="middle" justify="start" >
            <Col span={24} className="card-cantidad-blue">${(venta_dia).toMoney(true)} MXN</Col>
            <Col span={24} className="ant-card-head-title">Venta Mensual</Col>
            <Col span={24} className="card-cantidad-blue">${(venta_mes).toMoney(true)} MXN</Col>
        </Row>

    </Card>
}

/**
 * @const CardInfoCustomerHeader
 * @description  Card con la informacion de encabezado en vistas cliente
 */
function CardInfoCustomerHeader(props) {

    return <Card style={{ borderRadius: "20px", background: props.bg_color, border: 'none' }}>
        <Row>

            <Col span={22}>
                <Title style={{ margin: "0px" }} level={5} className='text-white'>{props.titulo} </Title>
            </Col>
            <Col span={2}>
                {
                    (props.icon === "IconPdtCot") ? <IconPdtCot /> :
                        (props.icon === "IconPdtAcp") ? <IconPdtAcp /> :
                            (props.icon === "IconPrdAcp") ? <IconPrdAcp /> :
                                (props.icon === "IconPrdPrc") ? <IconPrdPrc /> :
                                    null}
            </Col>
        </Row>

        <Title style={{ margin: "0px" }} className='text-white' >{props.valor}</Title>
        <Text className='text-white '  >{props.texto}</Text>
    </Card>
}


/**
 * @const CardPersonal
 * @description Card con los datos del mejor  y peor personal
 * @param title Titulo del Card
 * @param personal Objecto con los datos de las personas
 */
const CardPersonal = ({ primero = {}, ultimo = {}, title = "Personal" }) => {
    return <Card className=" card-shadow " style={{ minHeight: 216, height: '100%' }}>
        <Card.Meta
            title={<Title level={4} className="element-title ">{title}</Title>}
        />
        <Row gutter={[8, 8]}>
            <Col span={24}>
                <Title level={5} className='card-text-blue'>Mas Eficiente</Title>
                <Space>
                    {
                        (primero.nombre != undefined) ?
                            <>
                                <CustomAvatar name={`${primero?.nombre} ${primero?.apellido}`} />
                                <Text>{`${primero?.nombre} ${primero?.apellido}`}</Text>
                            </>
                            : <>
                                <CustomAvatar name={`N A`} />
                                <Text>{`No Asignado`}</Text>
                            </>}
                </Space>
            </Col>
            {/* <Col span={24}>
                <Title level={5} className='card-text-blue'>Menos Eficiente</Title>
                <Space>
                    {(ultimo.nombre != undefined) ? <>
                        <CustomAvatar name={`${ultimo?.nombre} ${ultimo?.apellido}`} />
                        <Text>{`${ultimo?.nombre} ${ultimo?.apellido}`}</Text>
                    </>
                        :
                        <>
                            <CustomAvatar name={`N A`} />
                            <Text>{`No Asignado`}</Text>
                        </>}
                </Space>
            </Col> */}
        </Row>
    </Card>
}


/**
 * @const CardSimple
 * @description Card sencilla con titulo y texto
 * @param title Titulo del Card
 * @param monto Monto total del card
 */
const CardSimple = ({ title = "", monto = 0 }) => {
    return <Card className=' card-shadow' style={{ minHeight: 216, height: '100%' }}>
        <Card.Meta
            title={<Title level={4} className="element-title ">{title}</Title>}
        />
        <div className='flex' style={{ height: 100 }}>
            <Statistic className='width-100 m-auto card-monto-strong prefix-tiny' prefix="$" suffix="MXN" value={monto} precision={2} />
        </div>
    </Card>
}

/**
 * @const CardVentas
 * @description Card con los datos de las ventas
 * @param title Titulo del Card
 * @param monto Monto total del card
 */
// const CardVentas = ({ title = "Compras Realizadas", porcentaje, ventas, cotizaciones }) => {
//     return 
// }

/**
 * @const CardPromedios
 * @description Card con la informacion de tiempos de cotizaciones
 * @param title Titulo del Card
 */
// const CardPromedios = ({ title = "Tiempo Promedio de Cotización", days, hours, minutes }) => {
//     return 
// }
/**
 * @const CardRazonSocial

 * @description Card con los datos de razon social
 */
const CardRazonSocial = ({ razon_social = {}, transacciones = [], facturas = [], onClick = () => { } }) => {

    // * 1 - Ingreso , 2 - Egreso ,3 - Traspaso ,4 - Nulo
    let trans_ingresos = transacciones.find(trans => trans._id == 1);
    let trans_egresos = transacciones.find(trans => trans._id == 2);



    //0 - Sin Cambios ,1 - Ingreso ,-1 - Egreso
    let fact_ingresos = facturas.find(fact => fact._id == 1);
    let fact_egresos = facturas.find(fact => fact._id == -1);

    return <Card
        onClick={onClick}
        hoverable={true}
        className="card-project card-shadow card-medium-small"
        title={
            <Row className=''>
                <Col span={4}>
                    <CustomAvatar
                        color={razon_social.color}
                        size="large"
                        image={razon_social.logo}
                        name={razon_social.razon_social}
                        style={{ marginRight: "10px" }}
                    />
                </Col>
                <Col span={20} className="flex-left" style={{ whiteSpace: 'normal' }}>
                    {razon_social.razon_social}
                </Col>
            </Row>
        }
    >
        <Row align="middle" justify="start" style={{ marginTop: "24px" }}>
            <Col span={24} className="m-1 flex-left">
                <Text ellipsis className="text-gray"> <FlagFilled className="mr-1" />
                    <span className='mt-1'>
                        Alta
                    </span>
                    <span>{moment(razon_social.createdAt).format('L')}</span>
                </Text>
            </Col>
        </Row>

        {/* Bancos */}
        <Row align="middle" justify="start" style={{ marginTop: "10px" }}>
            <Col span={24} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-blue">Bancos</Col>
                </Row>
            </Col>
            <Col xs={24} lg={12} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-text">Ingresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${(trans_ingresos?.monto) ? trans_ingresos?.monto.toMoney(true) : 0} MXN</Col>
                </Row>

            </Col>

            <Col xs={24} lg={12} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-text">Egresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${(trans_egresos?.monto) ? trans_egresos?.monto.toMoney(true) : 0} MXN</Col>
                </Row>

            </Col>
        </Row>

        {/* Facturación */}
        <Row align="middle" justify="start" style={{ marginTop: "10px" }}>
            <Col span={24} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-blue">Facturación</Col>
                </Row>

            </Col>

            <Col xs={24} lg={12} >
                <Row align="middle" justify="start"  >
                    <Col span={24} className="card-rs-text">Ingresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${((fact_ingresos?.monto) ? fact_ingresos?.monto : 0).toMoney(true)} MXN</Col>
                </Row>
            </Col>
            <Col xs={24} lg={12} >

                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-text">Egresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${((fact_egresos?.monto) ? fact_egresos?.monto : 0).toMoney(true)} MXN</Col>
                </Row>

            </Col>
        </Row>

    </Card>
}

/**
 * @const CardConciliacion_1

 * @description Card con los datos de razon social
 */
const MontosFaltantes = ({ transacciones = [], facturas = [], onClick = () => { } }) => {




    // * 1 - Ingreso , 2 - Egreso ,3 - Traspaso ,4 - Nulo
    let trans_ingresos = transacciones.find(trans => trans._id == 1);
    let trans_egresos = transacciones.find(trans => trans._id == 2);


    //0 - Sin Cambios ,1 - Ingreso ,-1 - Egreso
    let fact_ingresos = facturas.find(fact => fact._id == 1);
    let fact_egresos = facturas.find(fact => fact._id == -1);



    return <Card
        onClick={onClick}
        hoverable={true}
        className="card-project card-shadow card-medium-small"

        title={
            <Row className='mb-1 p-1'>
                <Text strong className='pt-5px'>
                    Montos Faltantes
                </Text>
            </Row>
        }
    >

        <Row align="middle" justify="start" style={{ marginTop: "10px" }}>
            <Col span={24} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-blue">Bancos</Col>
                </Row>
            </Col>

            <Col xs={24} lg={12} >
                <Row align="middle" justify="start"  >
                    <Col span={24} className="card-rs-text">Ingresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${((trans_ingresos?.orden_total - trans_ingresos?.monto_transacciones > 0) ? trans_ingresos?.orden_total - trans_ingresos?.monto_transacciones : 0).toMoney()} MXN</Col>
                </Row>
            </Col>
            <Col xs={24} lg={12} >

                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-text">Egresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${((trans_egresos?.orden_total - trans_egresos?.monto_transacciones > 0) ? trans_egresos?.orden_total - trans_egresos?.monto_transacciones : 0).toMoney()} MXN</Col>
                </Row>

            </Col>
        </Row>

        {/* Facturación */}
        <Row align="middle" justify="start" style={{ marginTop: "10px" }}>
            <Col span={24} >
                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-blue">Facturación</Col>
                </Row>

            </Col>

            <Col xs={24} lg={12} >
                <Row align="middle" justify="start"  >
                    <Col span={24} className="card-rs-text">Ingresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${((fact_ingresos?.orden_total - fact_ingresos?.monto_factura) ? fact_ingresos?.orden_total - fact_ingresos?.monto_factura : 0).toMoney()} MXN</Col>
                </Row>
            </Col>
            <Col xs={24} lg={12} >

                <Row align="middle" justify="start" >
                    <Col span={24} className="card-rs-text">Egresos</Col>
                    <Col span={24} className="card-rs-cantidad"> ${((fact_egresos?.orden_total - fact_egresos?.monto_factura) ? fact_egresos?.orden_total - fact_egresos?.monto_factura : 0).toMoney()} MXN</Col>
                </Row>

            </Col>
        </Row>

    </Card>

}




/**
 * @const CardEstatusContabilidad

 * @description Card con los datos de razon social
 */
const CardEstatusContabilidad = ({ SinSoporte = 0, Incompleto = 0, Completo = 0, onClick = () => { } }) => {
    return <Card
        onClick={onClick}
        hoverable={true}
        className="card-project card-shadow card-medium-small"

        title={
            <Row className='mb-1 p-1'>
                <Text strong className='pt-5px'>
                    Estatus
                </Text>
            </Row>
        }
    >
        <Row align="middle" justify="start" style={{ marginTop: "10px" }}>
            <Col span={24} >
                <Row align="middle" justify="start" className='row-sinSoporte'>
                    <Col span={20} >
                        <IconError />
                        <span className='spn-titulo'>
                            Sin Soporte
                        </span>
                    </Col>
                    <Col span={4} >
                        <Text strong >{SinSoporte}</Text>
                    </Col>
                </Row>
                <Row align="middle" justify="start" className='row-incompleto'>
                    <Col span={20} >
                        <IconWarning />
                        <span className='spn-titulo'>
                            Incompleto
                        </span>

                    </Col>
                    <Col span={4} >
                        <Text strong >{Incompleto}</Text>
                    </Col>
                </Row>

                <Row align="middle" justify="start" className='row-completo'>
                    <Col span={20} >
                        <IconSuccess />
                        <span className='spn-titulo'>
                            Completo
                        </span>

                    </Col>
                    <Col span={4} >
                        <Text strong >{Completo}</Text>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Card>
}

const ProductoCard = ({ producto, onAdd, onAddCarritoCompra = () => {} }) => {
    
    let url = "/images/nofile.png";
    let history = useHistory()

    if (producto?.imagenes?.length) {
        if (producto.imagenes[0].filename) {
            url = `${axios.defaults.baseURL}/upload/${producto.imagenes[0].filename}`;
        }
        if (producto.imagenes[0].url) {
            url = producto.imagenes[0].url;
        }
    }

    return (
        <div className="producto-card" onClick={() => history.push(`/producto/${producto._id}`)}>
            <img src={url} className="card-img" />
            <Paragraph ellipsis={{ rows: 2 }} className="card-text">
                {producto.descripcion}
            </Paragraph>
            <Paragraph ellipsis={{ rows: 1 }} className="card-price">
                {producto.precio_unitario ? `$ ${producto.precio_unitario.toFixed(2)} MXN` : null}
            </Paragraph>
            {producto.precio_unitario ? (
                <Button
                    className="card-button"
                    icon={<ShoppingCartOutlined style={{ color: "inherit", fontSize: 18, lineHeight: "5px" }} />}
                    onClick={(e) => {
                        e.stopPropagation(); // Evitar que el evento del contenedor se dispare
                        onAddCarritoCompra()
                    }}
                >
                    Añadir al carrito
                </Button>
            ) : (
                <Button
                    className="card-button"
                    onClick={(e) => {
                        e.stopPropagation(); // Evitar que el evento del contenedor se dispare
                        onAdd(); // Llamar a la función para agregar a cotizador
                    }}
                >
                    Agregar a cotizador
                </Button>
            )}
        </div>
    );
};


export {
    CardRazonSocial,
    MontosFaltantes,
    CardEstatusContabilidad,

    CardInfoCustomerHeader,


    CardTaskClient,
    CardTaskStep,
    CardClientProductos,
    CardSaldosGraph,
    CardOperations,
    CardOperationsList,
    CardSaldo,
    CardCuenta,
    CardGains,
    CardProducto,
    CardTaskProgress,
    CardOrdenProgress,
    CardProjectInfo,
    CardClientInfo,
    CardEntrega,
    CardUtilidad,
    CardLegalInfo,
    CardAccountPurchases,
    CardProductEstatus,
    CardProductsVentas,
    CardVMIInfo,
    CardIncomeExpense,
    CardFactura,
    CardOrdenCompra,


    CardCustomerProjectInfo,
    CardCustomerProgresoOrden,
    CardCustomerEntrega,
    CardCustomerRowCotizacion,
    CardCustomerProjectManager,
    CardCustomerPMCompras,

    CardPersonal,
    CardSimple,
    // CardVentas,
    // CardPromedios

    ProductoCard
}