import React, { useState, useEffect } from 'react';
import { List, Drawer, Typography } from 'antd';

import axios from "axios"

const DrawerFamilia = (props) => {

  const { open, onClose, onSelectFamilia = () => {} } = props

  const [familias, setFamilias] = useState([]);
  const [familiaSeleccionadas, setFamiliaSeleccionadas] = useState([])


  const getFamilias = async () => {
    try {
        const { data } = await axios.get('/familias');
        setFamilias(data);
    } catch (error) {
        console.error("Error al obtener las familias:", error);
    }
};

  const selectFamilia = (familia) => {
    let familias_obj = { ...familiaSeleccionadas };

    if(familias_obj[familia?._id]){
      delete familias_obj[familia?._id]
    }else{
      familias_obj[familia?._id] = familia?.nombre
    }

    onSelectFamilia(familias_obj)
    setFamiliaSeleccionadas(familias_obj)

  }


  useEffect(() => {
    getFamilias()
  },[])


  return (
    <>
      <Drawer 
        title="Categorias" 
        onClose={onClose} 
        open={open} 
        placement={"left"}
      >
        <List
          dataSource={familias}
          renderItem={(item) => (
            <List.Item className="hover pl-1" onClick={()=>selectFamilia(item)} style={{backgroundColor:  familiaSeleccionadas[item?._id] ? "#c3beb8" : "white"}}>
              <Typography.Text>{item.nombre}</Typography.Text> 
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
};


export default DrawerFamilia;